import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ShieldIcon = createIcon({
  displayName: "ShieldIcon",
  viewBox: "0 0 64 65",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" fill="none">
      <mask id="path-1-inside-1_5965_20111" fill="white">
        <path d="M8.49988 10.8519C8.49988 8.72453 10.2244 7 12.3517 7H52.648C54.7753 7 56.4999 8.72453 56.4999 10.8519V35C56.4999 48.2548 45.7547 59 32.4999 59C19.245 59 8.49988 48.2548 8.49988 35V10.8519Z" />
      </mask>
      <path
        d="M12.3517 11H52.648V3H12.3517V11ZM52.4999 10.8519V35H60.4999V10.8519H52.4999ZM12.4999 35V10.8519H4.49988V35H12.4999ZM32.4999 55C21.4542 55 12.4999 46.0457 12.4999 35H4.49988C4.49988 50.464 17.0359 63 32.4999 63V55ZM52.4999 35C52.4999 46.0457 43.5456 55 32.4999 55V63C47.9639 63 60.4999 50.464 60.4999 35H52.4999ZM52.648 11C52.5662 11 52.4999 10.9337 52.4999 10.8519H60.4999C60.4999 6.51539 56.9845 3 52.648 3V11ZM12.3517 3C8.01527 3 4.49988 6.5154 4.49988 10.8519H12.4999C12.4999 10.9337 12.4336 11 12.3517 11V3Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_5965_20111)"
      />
      <mask id="path-3-inside-2_5965_20111" fill="white">
        <path d="M16.4999 17.6667C16.4999 16.1939 17.6938 15 19.1665 15H45.8332C47.306 15 48.4999 16.1939 48.4999 17.6667V35C48.4999 43.8366 41.3364 51 32.4999 51C23.6633 51 16.4999 43.8366 16.4999 35V17.6667Z" />
      </mask>
      <path
        d="M19.1665 19H45.8332V11H19.1665V19ZM44.4999 17.6667V35H52.4999V17.6667H44.4999ZM20.4999 35V17.6667H12.4999V35H20.4999ZM32.4999 47C25.8725 47 20.4999 41.6274 20.4999 35H12.4999C12.4999 46.0457 21.4542 55 32.4999 55V47ZM44.4999 35C44.4999 41.6274 39.1273 47 32.4999 47V55C43.5456 55 52.4999 46.0457 52.4999 35H44.4999ZM45.8332 19C45.0968 19 44.4999 18.403 44.4999 17.6667H52.4999C52.4999 13.9848 49.5151 11 45.8332 11V19ZM19.1665 11C15.4846 11 12.4999 13.9848 12.4999 17.6667H20.4999C20.4999 18.403 19.9029 19 19.1665 19V11Z"
        fill="currentColor"
        mask="url(#path-3-inside-2_5965_20111)"
      />
      <path
        d="M26.4999 25.1429C26.4999 25.064 26.5638 25 26.6427 25H37.357C37.4359 25 37.4999 25.064 37.4999 25.1429V35.5C37.4999 38.5376 35.0374 41 31.9999 41C28.9623 41 26.4999 38.5376 26.4999 35.5V25.1429Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
