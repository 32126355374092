export default {
  header: {
    h: "8.125rem",
  },
  container: {
    xl: "86rem",
  },
  triangle: {
    h: "20vw",
  },
  divot: {
    h: "3vw",
  },
};
