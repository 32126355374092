import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import layerStyles from "../layer_styles";
import textStyles from "../text_styles";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    // different padding top & bottom to make text veritcally centered
    // as meta3 is using uppercase
    ...textStyles.meta3,
    pt: "0.35rem",
    pb: "0.15rem",
    bg: "neutral.white",
    color: "neutral.800",
    px: "0.625rem",
    borderRadius: "0.5rem",
  },
});

const brand = definePartsStyle({
  container: {
    ...layerStyles.gradients.linear.brand.default.colors,
    color: "neutral.white",
  },
});

const vulcan = definePartsStyle({
  container: {
    ...layerStyles.gradients.linear.vulcan.default.colors,
    color: "neutral.white",
  },
});

const citrus = definePartsStyle({
  container: {
    ...layerStyles.gradients.linear.citrus.default.colors,
    color: "neutral.white",
  },
});

const stone = definePartsStyle({
  container: {
    ...layerStyles.gradients.linear.stone.default.colors,
    color: "neutral.white",
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    brand,
    vulcan,
    citrus,
    stone,
  },
});
