import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { trackInputEntered } from "../../utils";

import { ErrorMessage } from ".";

export const setSubmitError = (
  methods,
  message = ErrorMessage.GenericSubmitError,
) => {
  const { setError } = methods;
  return setError("root.server", { message });
};

// more see: https://react-hook-form.com/docs/useform
export const useArtaForm = ({ ...props }) => {
  return useForm({ mode: "all", ...props });
};

export const useTrackInputEntered = ({ methods, trackingProperties, name }) => {
  const { formState, getFieldState } = methods;
  const { isDirty, invalid } = getFieldState(name, formState);
  const hasEnteredValidValue = isDirty && !invalid;

  useEffect(() => {
    if (hasEnteredValidValue) {
      trackInputEntered({ ...trackingProperties, Field: name });
    }
  }, [hasEnteredValidValue]);
};
