import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Select,
} from "@chakra-ui/react";

import countries from "../../data/countries";

import { ConnectForm, useArtaFormContext, useTrackInputEntered } from ".";

// in alpha2Code
const PRIORITY_LIST = ["US"];

const options = Object.values(countries)
  .sort((a, b) => a.name.localeCompare(b.name))
  .sort((a, b) => {
    const aIndex = PRIORITY_LIST.indexOf(a.alpha2Code);
    const bIndex = PRIORITY_LIST.indexOf(b.alpha2Code);

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });

export const ArtaCountrySelect = ({ name, helpText, ...otherProps }) => {
  const { trackingProperties } = useArtaFormContext();

  return (
    <ConnectForm>
      {(methods) => {
        const { formState, getFieldState, register } = methods;
        const { isSubmitting } = formState;
        const fieldState = getFieldState(name, formState);
        const { isTouched, error } = fieldState;

        const showError = isTouched && error;

        useTrackInputEntered({ methods, trackingProperties, name });

        return (
          <FormControl isInvalid={showError}>
            <Select
              {...register(name)}
              variant="outline"
              size="xl"
              isDisabled={isSubmitting}
              placeholder="Select a country"
              {...otherProps}
            >
              {options.map(({ name, alpha2Code }) => (
                <option key={alpha2Code} value={alpha2Code}>
                  {name}
                </option>
              ))}
            </Select>
            {showError ? (
              <FormErrorMessage>{error.message}</FormErrorMessage>
            ) : helpText ? (
              <FormHelperText>{helpText}</FormHelperText>
            ) : (
              // HACK: Keep the layout consistent when there is no help text, or when there is an error
              <FormHelperText>&nbsp;</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </ConnectForm>
  );
};
