import React from "react";
import { useFormContext } from "react-hook-form";

import ArtaButton, { ContentfulButton } from "../button";

import { useArtaFormContext } from ".";

export const ArtaSubmitButton = ({ ...otherProps }) => {
  const buttonProps = useButtonProps();

  return <ArtaButton {...buttonProps} {...otherProps} />;
};

export const ArtaContentfulSubmitButton = ({ data, ...otherProps }) => {
  const buttonProps = useButtonProps();

  return (
    <ContentfulButton
      data={data}
      loadingText={data.text}
      {...buttonProps}
      {...otherProps}
    />
  );
};

const useButtonProps = () => {
  const { trackingProperties } = useArtaFormContext();
  const {
    formState: { isSubmitted, isSubmitting, isValid },
  } = useFormContext();

  return {
    to: null,
    type: "submit",
    size: { base: "small", md: "default" },
    isDisabled: !isValid && !isSubmitted && !isSubmitting,
    isLoading: isSubmitting,
    spinnerPlacement: "end",
    trackingProperties,
  };
};
