import { ExperimentClient } from "@amplitude/experiment-js-client";

import { IPreviewVariants } from "../contexts";
import { ContentfulTypename } from "../templates/contentful_page/enums";
import {
  IDevExperiment,
  IDevVariant,
  OFF_VALUE,
} from "../templates/contentful_page/experiments";
import {
  IContentfulExperiment,
  IContentfulItem,
  IContentfulPageData,
  IContentfulVariant,
} from "../templates/contentful_page/interfaces";

export const getVariantItems = (
  item: IContentfulExperiment,
  experiment?: ExperimentClient | null,
  previewVariants?: IPreviewVariants,
) => {
  return getVariant<IContentfulExperiment, IContentfulVariant>(
    item,
    experiment,
    previewVariants,
  ).items;
};

export const getDevVariant = (
  item: IDevExperiment,
  experiment?: ExperimentClient | null,
  previewVariants?: IPreviewVariants,
) => {
  return getVariant<IDevExperiment, IDevVariant>(
    item,
    experiment,
    previewVariants,
  );
};

export const getVariant = <
  T extends IDevExperiment | IContentfulExperiment,
  R extends IDevVariant | IContentfulVariant,
>(
  item: T,
  experiment?: ExperimentClient | null,
  previewVariants?: IPreviewVariants,
): R => {
  const { experimentId, variantCollection, fallbackVariantId } = item;

  const previewVariantId = previewVariants?.[experimentId]?.value;
  const shouldForceVariant =
    previewVariantId !== undefined && previewVariantId !== OFF_VALUE;
  const forcedVariantId = shouldForceVariant ? previewVariantId : undefined;

  // If user hasn't been bucketed into a variant, experiment.variant will
  // return null. In such cases, we will show these users a fallback variant.
  //
  // The variant found through fallbackVariantId will be used. If
  // fallbackVariantId is empty or is invalid, we will then use the first
  // variant in VariantCollection as the fallback.
  const fallback =
    variantCollection.find(
      ({ variantId }) => variantId === fallbackVariantId,
    ) || variantCollection[0];

  // If experiment has not been loaded or failed to load, show the fallback.
  // If preview for a variant is selected, the preview takes precedence.
  if (!experiment) {
    const forcedVariant = variantCollection.find(
      ({ variantId }) => variantId === forcedVariantId,
    );
    return (forcedVariant || fallback) as R;
  }

  // Variant to show is done on Amplitude's side via remote evaluation.
  // Retrieve the evaluated variant and return the corresponding Contentful
  // data block. If preview for a variant is selected, the preview takes
  // precedence.
  const resolvedVariantId =
    forcedVariantId ||
    experiment.variant(experimentId, fallback.variantId).value;

  const resolvedVariant =
    variantCollection.find(
      ({ variantId }) => variantId === resolvedVariantId,
    ) || fallback;

  return resolvedVariant as R;
};

export const getExperiments = (
  pageData: IContentfulPageData,
): IContentfulExperiment[] => {
  const experiments: IContentfulExperiment[] = [];
  const sections = pageData.sections;

  function get(items: IContentfulItem[] | null) {
    items?.forEach((item) => {
      switch (item.__typename) {
        case ContentfulTypename.Experiment:
          experiments.push(item as IContentfulExperiment);
          break;
        case ContentfulTypename.UiSection: {
          const { items } = item;
          get(items);
          break;
        }
      }
    });
  }

  get(sections);
  return experiments;
};
