import textures from "../textures";

const gradientTexture = textures.noise[256];

export default {
  lunar: {
    soft: {
      colors: {
        backgroundImage:
          "conic-gradient(from 120deg at 50% 50%, #7772A5 0deg, tropic.700 60deg, lunar.600 120deg, lunar.500 180deg, marine.300 240deg, marine.400 300deg, #7772A5 360deg)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.64,
      },
    },
  },
};
