import axios from "axios";

export const downloadFile = async (fileUrl, fileName) => {
  const res = await axios({
    responseType: "blob",
    url: fileUrl,
  });
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
