import { extendTheme } from "@chakra-ui/react";

import components from "./components";

import colors from "./colors";
import fonts from "./fonts";
import layerStyles from "./layer_styles";
import shadows from "./shadows";
import sizes from "./sizes";
import styles from "./styles";
import textStyles from "./text_styles";
import transition from "./transition";

const theme = {
  colors,
  components,
  fonts,
  layerStyles,
  shadows,
  sizes,
  styles,
  textStyles,
  transition,
};

export default extendTheme(theme);
