export default {
  // =============== display ===============
  display0: {
    fontFamily: "Ofelia-Display",
    fontSize: "4.5rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.1875rem",
    textTransform: "none",
  },
  display0Em: {
    fontFamily: "Ofelia-Display",
    fontSize: "4.5rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.1875rem",
    textTransform: "none",
  },
  display1: {
    fontFamily: "Ofelia-Display",
    fontSize: "3.5rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.1875rem",
    textTransform: "none",
  },
  display1Em: {
    fontFamily: "Ofelia-Display",
    fontSize: "3.5rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.1875rem",
    textTransform: "none",
  },
  display2: {
    fontFamily: "Ofelia-Display",
    fontSize: "2.5rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.125rem",
    textTransform: "none",
  },
  display2Em: {
    fontFamily: "Ofelia-Display",
    fontSize: "2.5rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.125rem",
    textTransform: "none",
  },
  display3: {
    fontFamily: "Ofelia-Display",
    fontSize: "1.75rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.125rem",
    textTransform: "none",
  },
  display3Em: {
    fontFamily: "Ofelia-Display",
    fontSize: "1.75rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.125rem",
    textTransform: "none",
  },
  // =============== headline ===============
  headline4: {
    fontFamily: "Rotunda",
    fontSize: "1.5rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.075rem",
    textTransform: "none",
  },
  headline4Em: {
    fontFamily: "Rotunda",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.075rem",
    textTransform: "none",
  },
  headline5: {
    fontFamily: "Rotunda",
    fontSize: "1.25rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.0625rem",
    textTransform: "none",
  },
  headline5Em: {
    fontFamily: "Rotunda",
    fontSize: "1.25rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.0625rem",
    textTransform: "none",
  },
  headline6: {
    fontFamily: "Rotunda",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "130%",
    letterSpacing: "0.05rem",
    textTransform: "none",
  },
  headline6Em: {
    fontFamily: "Rotunda",
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "0.05rem",
    textTransform: "none",
  },
  // =============== body ===============
  body0: {
    fontFamily: "Rotunda",
    fontSize: "1.75rem",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "0.03125rem",
    textTransform: "none",
  },
  body0Em: {
    fontFamily: "Rotunda",
    fontSize: "1.75rem",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "0.03125rem",
    textTransform: "none",
  },
  body1: {
    fontFamily: "Rotunda",
    fontSize: "1.5rem",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "0.03125rem",
    textTransform: "none",
  },
  body1Em: {
    fontFamily: "Rotunda",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "0.03125rem",
    textTransform: "none",
  },
  body2: {
    fontFamily: "Rotunda",
    fontSize: "max(1.25rem, 14px)",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body2Em: {
    fontFamily: "Rotunda",
    fontSize: "max(1.25rem, 14px)",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body3: {
    fontFamily: "Rotunda",
    fontSize: "max(1rem, 14px)",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body3Em: {
    fontFamily: "Rotunda",
    fontSize: "max(1rem, 14px)",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body4: {
    fontFamily: "Rotunda",
    fontSize: "max(0.875rem, 14px)",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body4Em: {
    fontFamily: "Rotunda",
    fontSize: "max(0.875rem, 14px)",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body5: {
    fontFamily: "Rotunda",
    fontSize: "max(0.6875rem, 10px)",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  body5Em: {
    fontFamily: "Rotunda",
    fontSize: "max(0.6875rem, 10px)",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
  // =============== cap ===============
  cap1: {
    fontFamily: "Rotunda",
    fontSize: "2rem",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "0.5rem",
    textTransform: "uppercase",
  },
  cap2: {
    fontFamily: "Rotunda",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "150%",
    letterSpacing: "0.375rem",
    textTransform: "uppercase",
  },
  // =============== meta ===============
  meta1: {
    fontFamily: "Rotunda",
    fontSize: "1.125rem",
    fontWeight: "500",
    lineHeight: "200%",
    letterSpacing: "0.25rem",
    textTransform: "uppercase",
  },
  meta2: {
    fontFamily: "Rotunda",
    fontSize: "0.875rem",
    fontWeight: "700",
    lineHeight: "200%",
    letterSpacing: "0.1875rem",
    textTransform: "uppercase",
  },
  meta3: {
    fontFamily: "Rotunda",
    fontSize: "max(0.625rem, 10px)",
    fontWeight: "500",
    lineHeight: "200%",
    letterSpacing: "max(0.125rem, 2px)",
    textTransform: "uppercase",
  },
  // =========== interaction ===============
  webButton1: {
    fontFamily: "Rotunda",
    fontSize: "max(1.25rem, 14px)",
    fontWeight: "500",
    lineHeight: "100%",
    letterSpacing: "max(0.07813rem, 1.25px)",
    textTransform: "none",
  },
  webButton2: {
    fontFamily: "Rotunda",
    fontSize: "max(0.875rem, 14px)",
    fontWeight: "500",
    lineHeight: "1.5rem",
    letterSpacing: "max(0.09375rem, 1.5px)",
    textTransform: "none",
  },
  webNavigation: {
    fontFamily: "Rotunda",
    fontSize: { base: "14px", md: "0.75rem" },
    fontWeight: "500",
    lineHeight: "266.67%",
    letterSpacing: { base: "4px", md: "0.25rem" },
    textTransform: "uppercase",
  },
  // =============== misc ===============
  disclosures: {
    fontFamily: "Rotunda",
    fontSize: "max(0.875rem, 10px)",
    fontWeight: "400",
    lineHeight: "160%",
    letterSpacing: "max(0.03125rem, 0.5px)",
    textTransform: "none",
  },
};
