export default {
  global: {
    html: {
      // https://matthewjamestaylor.com/responsive-font-size
      fontSize: "min(7px + 0.390625vw, 16px)",
    },
    "*[id]": {
      scrollMarginTop: "9rem",
    },

    // hide scrollbar from react-horizontal-scrolling-menu
    // reference: https://codesandbox.io/s/no-scrollbar-and-buttons-position-c3kn5?file=/src/hideScrollbar.css
    ".react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar": {
      display: "none" /* chrome and chromium based */,
    },
    ".react-horizontal-scrolling-menu--scroll-container": {
      msOverflowStyle: "none" /* IE and Edge */,
      scrollbarWidth: "none" /* Firefox */,
    },
  },
};
