import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const LongRightArrowIcon = createIcon({
  displayName: "LongRightArrowIcon",
  viewBox: "0 0 108 64",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="64" fill="none">
      <g
        fill="currentColor"
        fillRule="evenodd"
        clipPath="url(#a)"
        clipRule="evenodd"
      >
        <path d="M96.667 32A2.667 2.667 0 0 0 94 29.333H14a2.667 2.667 0 0 0 0 5.334h80A2.667 2.667 0 0 0 96.667 32Z" />
        <path d="M95.886 30.114a2.667 2.667 0 0 0-3.771 0L81.448 40.781a2.667 2.667 0 1 0 3.771 3.771l10.667-10.666a2.667 2.667 0 0 0 0-3.772Z" />
        <path d="M81.448 19.448a2.667 2.667 0 0 0 0 3.77l10.667 10.668a2.667 2.667 0 1 0 3.77-3.772L85.22 19.448a2.667 2.667 0 0 0-3.771 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M107.333 0H.666v64h106.667z" />
        </clipPath>
      </defs>
    </svg>
  ),
});
