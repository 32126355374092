import React from "react";
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import {
  formatContentfulLiveUpdates,
  renderContentfulRichText,
} from "../utils";

const VARIANT_MAP = {
  default: {
    triggerColor: "marine.500",
    triggerTextStyle: "inherit",
  },
  muted: {
    triggerColor: "neutral.600",
    triggerTextStyle: "disclosure",
  },
};

const ContentfulTooltip = ({
  data,
  sup,
  inline,
  placement = "bottom-start",
  dotted = false,
  matchContentWidth = false,
  variant = "default",
  options,
}) => {
  const inspectorProps = useContentfulInspectorMode();
  const liveData = formatContentfulLiveUpdates({ data });

  if (!liveData) return null;

  const { text, content } = liveData;
  const { triggerColor, triggerTextStyle } =
    VARIANT_MAP[variant] ?? VARIANT_MAP.default;

  return (
    <Popover
      data-sentry-unmask
      trigger="hover"
      closeDelay="100"
      placement={placement}
    >
      <PopoverTrigger>
        <Box
          {...inspectorProps({
            entryId: data.contentful_id,
            fieldId: "text",
          })}
          as="span"
          display="inline"
          color={triggerColor}
          textStyle={triggerTextStyle}
          borderBottom={dotted ? "2px dotted" : "none"}
        >
          <Text
            as="span"
            cursor="default"
            lineHeight="100%"
            display={inline ? "inline" : "unset"}
          >
            {sup ? <sup>{text}</sup> : text}
          </Text>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          w={matchContentWidth ? "100%" : "42rem"}
          boxShadow="shadows.light.100"
          borderRadius="1rem"
        >
          <PopoverBody
            p="2rem"
            textStyle="body2"
            color="neutral.800"
            whiteSpace="break-spaces"
          >
            {renderContentfulRichText(content, options)}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ContentfulTooltip;
