export const isExternalLink = (link) => {
  if (!link) return false;

  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) {
    return (
      !link.startsWith("/") && !link.startsWith("#") && !link.startsWith("?")
    );
  }

  try {
    return new URL(link).origin !== window.location.origin;
  } catch {
    return false;
  }
};
