export default {
  baseStyle: (props) => ({
    ...props.theme.textStyles.webButton1,
    borderRadius: "62.5rem",
    background: "",
    color: "neutral.white",
    transition: "background 250ms cubic-bezier(0.4, 0, 0, 0.6)",
    ".chakra-button__icon": {
      transition: "all 250ms cubic-bezier(0.4, 0, 0, 0.6)",
      boxSize: "max(1.5rem, 24px)",
      svg: {
        height: "max(1.5rem, 24px)",
        width: "max(1.5rem, 24px)",
      },
    },
    _hover: {
      ".chakra-button__icon": {
        ms: props.rightIcon ? "1.25rem" : 0,
      },
    },
  }),
  sizes: {
    default: (props) => ({
      ...props.theme.textStyles.webButton2,
      pl: "max(2rem, 24px)",
      pr: props.rightIcon ? "max(1.5rem, 16px)" : "max(2rem, 24px)",
      py: "max(1.25rem, 16px)",
    }),
    // not pixel perfect
    small: (props) => ({
      ...props.theme.textStyles.webButton2,
      pl: "2rem",
      pr: props.rightIcon ? "2.5rem" : "2rem",
      py: "1rem",
    }),
    // not pixel perfect
    xsmall: (props) => ({
      ...props.theme.textStyles.webButton2,
      pl: "1.5rem",
      pr: props.rightIcon ? "1rem" : "2.5rem",
      py: "1rem",
      ".chakra-button__icon": {
        transition: "all 250ms cubic-bezier(0.4, 0, 0, 0.6)",
        boxSize: "2rem",
        svg: {
          height: "2rem",
          width: "2rem",
        },
      },
    }),
    navigation: (props) => ({
      ...props.theme.textStyles.webNavigation,
      pl: { base: "24px", md: "1.5rem" },
      pr: { base: "16px", md: "1rem" },
      py: { base: "14px", md: "0.75rem" },
      ".chakra-button__icon": {
        transition: "all 250ms cubic-bezier(0.4, 0, 0, 0.6)",
        boxSize: "1.25rem",
        svg: {
          height: "1.25rem",
          width: "1.25rem",
        },
      },
    }),
    smallChip: (props) => ({
      ...props.theme.textStyles.webButton2,
      pl: "2rem",
      pr: "1.75rem",
      py: "0.88rem",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "neutral.800",
      div: {
        ml: props.leftIcon ? "1rem" : "0",
        mr: props.rightIcon ? "1rem" : "0",
      },
      ".chakra-button__icon": {
        boxSize: "1.75rem",
        ms: 0,
        me: 0,
        svg: {
          height: "1.75rem",
          width: "1.75rem",
        },
      },
      _hover: {
        ".chakra-button__icon": {
          ms: 0,
        },
      },
    }),
    chip: (props) => ({
      ...props.theme.textStyles.webButton2,
      pl: "2rem",
      pr: "1rem",
      py: "1rem",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "neutral.800",
      ".chakra-button__icon": {
        boxSize: "1.75rem",
        ms: 0,
        me: "1rem",
        svg: {
          height: "1.75rem",
          width: "1.75rem",
        },
      },
      _hover: {
        ".chakra-button__icon": {
          ms: 0,
        },
      },
    }),
  },
  variants: {
    default: {
      backgroundImage:
        "linear(267deg, marine.300 0%, marine.400 51.56%, marine.400 100%)",
      _disabled: {
        opacity: 0.5,
        pointerEvents: "none",
      },
    },
    secondary: {
      color: "marine.400",
      // Border needs to be drawn inside the button for it to be the same size
      // as a button without a border. However, drawing inside is only possible
      // when a height is specified. Since we don't specify a height for our
      // buttons, we will draw the border inside with boxShadow instead.
      boxShadow: "inset 0px 0px 0px 2px #56b4e8", // marine.400
      _disabled: {
        boxShadow: "inset 0px 0px 0px 2px #56b4e8", // marine.400
        opacity: 0.5,
        pointerEvents: "none",
      },
    },
    secondaryAlt: {
      // Border needs to be drawn inside the button for it to be the same size
      // as a button without a border. However, drawing inside is only possible
      // when a height is specified. Since we don't specify a height for our
      // buttons, we will draw the border inside with boxShadow instead.
      boxShadow: "inset 0px 0px 0px 1px #ffffff", // neutral.white
      _disabled: {
        boxShadow: "inset 0px 0px 0px 1px #ffffff", // neutral.white
        opacity: 0.5,
        pointerEvents: "none",
      },
    },
    agnosticPrimary: {
      backgroundColor: "neutral.white",
      color: "neutral.950",
      _disabled: {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    agnosticPrimaryAlt: {
      backgroundColor: "neutral.950",
      _disabled: {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    agnosticSecondary: {
      backgroundColor: "neutral.800",
      _disabled: {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    agnosticSecondaryAlt: {
      color: "neutral.950",
      // Border needs to be drawn inside the button for it to be the same size
      // as a button without a border. However, drawing inside is only possible
      // when a height is specified. Since we don't specify a height for our
      // buttons, we will draw the border inside with boxShadow instead.
      boxShadow: "inset 0px 0px 0px 2px #171717", // neutral.950
      _disabled: {
        boxShadow: "inset 0px 0px 0px 2px #171717", // neutral.950
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    ghost: {
      backgroundColor: "transparent",
      color: "neutral.800",
      _hover: {
        color: "marine.500",
        backgroundColor: "transparent",
      },
      _focus: {
        backgroundColor: "transparent",
      },
      _disabled: {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    ghostSecondary: {
      backgroundColor: "rgba(255, 255, 255, 0.20)",
      color: "neutral.white",
      _hover: {
        backgroundColor: "rgba(255, 255, 255, 0.30)",
      },
      _disabled: {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    link: (props) => {
      var textStyleProps = props.theme.textStyles.body2;
      if (props.textStyle && props.theme.textStyles[props.textStyle]) {
        textStyleProps = props.theme.textStyles[props.textStyle];
      }
      return {
        ...textStyleProps,
        borderRadius: 0,
        color: "marine.400",
        _hover: {
          textDecoration: "none",
        },
      };
    },
    chip: () => {
      return {
        borderRadius: "62.5rem",
        color: "neutral.500",
        backgroundColor: "transparent",
        _hover: {
          backgroundColor: "neutral.50",
        },
        _active: {
          color: "neutral.white",
          backgroundColor: "neutral.800",
        },
      };
    },
    chipFilter: () => {
      return {
        borderRadius: "62.5rem",
        color: "neutral.800",
        backgroundColor: "transparent",
        ".chakra-button__icon": {
          svg: {
            filter: "saturate(0%)",
          },
        },
        _hover: {
          backgroundColor: "neutral.50",
          ".chakra-button__icon": {
            svg: {
              filter: "none",
            },
          },
        },
        _active: {
          color: "neutral.white",
          backgroundColor: "neutral.800",
          ".chakra-button__icon": {
            svg: {
              filter: "none",
            },
          },
        },
      };
    },
    select: (props) => ({
      ...props.theme.textStyles.body0,
      borderBottom: "2px",
      borderColor: "marine.500",
      color: "marine.500",
      borderRadius: 0,
      paddingInlineStart: "0rem",
      height: "2.5rem",
      py: "0",
      pr: 0,
      _hover: {
        ".chakra-button__icon": {
          ms: "1.5rem",
        },
      },
      ".chakra-button__icon": {
        color: "marine.500",
        boxSize: "1.5rem",
        ms: "1.5rem",
        svg: {
          height: "1.5rem",
          width: "1.5rem",
        },
      },
    }),
    indicator: (props) => {
      const { isActive } = props;
      return {
        w: {
          base: isActive ? "3rem" : "2rem",
          md: isActive ? "5.75rem" : "4rem",
        },
        h: "0.375rem",
        p: 0,
        borderRadius: "0.25rem",
        backgroundColor: isActive ? "marine.400" : "neutral.200",
        _hover: {
          backgroundColor: isActive ? "marine.400" : "marine.200",
        },
        transition: "all 200ms linear",
      };
    },
  },
  defaultProps: {
    size: "default",
    variant: "default",
    iconSpacing: "1rem",
  },
};
