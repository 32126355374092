import React, { useState } from "react";
import { getDeviceId } from "@amplitude/analytics-browser";
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IconCheck, IconEdit } from "@tabler/icons-react";
import { isEmpty } from "lodash";

import { getExperiments, isMobile } from "../../../utils";

import { IPreviewVariants, useAmplitudeExperiment } from "../../../contexts";
import { IContentfulPageData } from "../interfaces";

import { devExperimentsData } from "./dev-experiments";

// Used as the radio value for the option that does not force any variant
// preview on the page.
//
// The value has to be treated as a reserved keyword where it should not be
// used as a variant value in any Amplitude Experiment.
export const OFF_VALUE = "SHOW_AMPLITUDE_ASSIGNED";

const Z_INDEX = "105"; // Above navbar, below cookie sheet
const HORIZONTAL_PADDING = "1rem";
const AMPLITUDE_BRAND_COLORS = {
  primary: "#1e61f0",
  primaryAlt: "#1648b2",
  secondary: "#301661",
};

export interface IAmplitudeExperimentPreviewerProps {
  pageData: IContentfulPageData;
}

export const AmplitudeExperimentPreviewer = ({
  pageData,
}: IAmplitudeExperimentPreviewerProps) => {
  const { previewVariants, setPreviewVariants } = useAmplitudeExperiment();
  const experiments = getExperiments(pageData);
  const hasExperiments =
    devExperimentsData.length > 0 || experiments.length > 0;

  // Default options are for initialising to show Amplitude's assigned variant
  const defaultOptions = [...devExperimentsData, ...experiments].reduce(
    (acc, { experimentId }) => {
      return { ...acc, [experimentId]: { value: OFF_VALUE } };
    },
    {},
  );

  const [options, setOptions] = useState<IPreviewVariants>(
    isEmpty(previewVariants) ? defaultOptions : previewVariants,
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (experimentId: string, value: string) => {
    const newOptions = { ...options, [experimentId]: { value } };
    setOptions(newOptions);
    setPreviewVariants(newOptions);
  };

  const resetOptions = () => {
    setOptions(defaultOptions);
    setPreviewVariants(defaultOptions);
  };

  if (!hasExperiments) {
    return (
      <>
        <DrawerToggle isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <Drawer
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          resetOptions={resetOptions}
          disableReset={true}
        >
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            h="100%"
          >
            <Text textStyle="body2" color="neutral.500" textAlign="center">
              There are no experiments on this page.
            </Text>
          </Flex>
        </Drawer>
      </>
    );
  }

  return (
    <>
      <DrawerToggle isOpen={isOpen} toggleDrawer={toggleDrawer} />
      <Drawer
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        resetOptions={resetOptions}
      >
        {devExperimentsData.map((experiment) => (
          <ExperimentCard
            key={experiment.experimentId}
            experiment={experiment}
            selectedOption={options[experiment.experimentId]}
            onChange={handleChange}
            showContentfulLink={false}
          />
        ))}

        {experiments.map((experiment) => (
          <ExperimentCard
            key={experiment.experimentId}
            experiment={experiment}
            selectedOption={options[experiment.experimentId]}
            onChange={handleChange}
          />
        ))}
      </Drawer>
    </>
  );
};

const Drawer = ({
  isOpen,
  toggleDrawer,
  resetOptions,
  disableReset = false,
  children,
  ...otherProps
}) => {
  const DRAWER_WIDTH = useBreakpointValue({ base: "100%", md: "25rem" });

  return (
    <Box
      position="fixed"
      right="0"
      zIndex={Z_INDEX}
      bgColor="neutral.white"
      boxShadow="rgba(0, 0, 0, 0.2) 0px 0px 3px 1px"
      w={DRAWER_WIDTH}
      h="100%"
      transition="transform 0.3s ease-in-out"
      transform={`translateX(${isOpen ? "0" : DRAWER_WIDTH})`}
      {...otherProps}
    >
      <Grid gridTemplateRows="auto 1fr auto" w="100%" h="100%" pt="2rem">
        <Flex direction="column" gap="2rem" px={HORIZONTAL_PADDING} mb="2rem">
          <Text textStyle={{ base: "display2Em", md: "display3Em" }}>
            Amplitude Experiment Previewer
          </Text>
          <DeviceIdDisplay />
        </Flex>

        <Flex
          direction="column"
          gap="2rem"
          px={HORIZONTAL_PADDING}
          overflowY="auto"
          pb="2rem"
        >
          {children}
        </Flex>

        <CtaButtons
          onClose={toggleDrawer}
          onReset={resetOptions}
          disableReset={disableReset}
        />
      </Grid>
    </Box>
  );
};

const DrawerToggle = ({ isOpen, toggleDrawer }) => {
  const isOpenY = useBreakpointValue({ base: "-100vw", md: "-17.5rem" });

  return (
    <Box
      position="fixed"
      right="0"
      bottom="20%"
      zIndex={Z_INDEX}
      textStyle="headline5Em"
      color="neutral.white"
      bgColor={AMPLITUDE_BRAND_COLORS.primary}
      cursor="pointer"
      px="2rem"
      py="1rem"
      borderTopRadius="8px"
      boxShadow="base"
      transition="transform 0.3s ease-in-out"
      transform={`rotate(-90deg) translateY(${isOpen ? isOpenY : "7.5rem"})`}
      onClick={toggleDrawer}
      _hover={{ bgColor: AMPLITUDE_BRAND_COLORS.primaryAlt }}
    >
      Amplitude Experiment
    </Box>
  );
};

const DeviceIdDisplay = () => {
  const deviceId = getDeviceId();
  const [isCopied, setIsCopied] = useState(false);
  const toast = useToast();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    toast({
      title: "Device ID copied!",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <VStack align="start">
      <Text textStyle="meta3">Your current device ID:</Text>
      <HStack w="100%">
        <Text
          textStyle={{ base: "body2", md: "body4" }}
          color="neutral.600"
          bg="neutral.100"
          border="1px"
          borderColor="neutral.200"
          px="4px"
          py="2px"
          borderRadius="4px"
          fontSize={{ base: "max(1.25rem, 14px)", md: "0.9rem" }}
          fontWeight={{ base: "400", md: "400" }}
          w="100%"
        >
          {deviceId}
        </Text>
        {/**
         * Only display copy to clipboard icon for non-mobile because
         * copyToClipboard currently does not work for mobile devices.
         */}
        {!isMobile() && (
          <IconButton
            aria-label="Copy device id"
            icon={isCopied ? <Icon as={IconCheck} /> : <CopyIcon />}
            variant="ghost"
            onClick={() =>
              isCopied ? undefined : copyToClipboard(deviceId || "")
            }
          />
        )}
      </HStack>
      <Text textStyle="disclosures" color="neutral.600">
        Use this device ID to add yourself as a tester in an experiment to
        activate the test instrumentation.
      </Text>
    </VStack>
  );
};

const ExperimentCard = ({
  experiment,
  selectedOption,
  onChange,
  showContentfulLink = true,
  ...otherProps
}) => {
  const { contentful_id, experimentId, variantCollection } = experiment;
  const spaceId = process.env.CONTENTFUL_SPACE_ID;
  const environment = process.env.CONTENTFUL_ENVIRONMENT;
  const contentfulLink = `https://app.contentful.com/spaces/${spaceId}/environments/${environment}/entries/${contentful_id}`;

  return (
    <Card
      border="1px"
      borderColor="neutral.200"
      boxShadow="none"
      {...otherProps}
    >
      <CardBody py="1rem">
        <HStack justifyContent="space-between">
          <Text textStyle="meta3" color="neutral.500">
            Experiment
          </Text>

          {showContentfulLink && (
            <Link href={contentfulLink} target="_blank" rel="noopener">
              <Icon
                as={IconEdit}
                boxSize="1.5rem"
                color="neutral.500"
                _hover={{ color: "marine.500" }}
              />
            </Link>
          )}
        </HStack>
        <Text textStyle={{ base: "body0", md: "body1" }} mt="-0.5rem" mb="1rem">
          {experimentId}
        </Text>

        <RadioGroup value={selectedOption.value}>
          <VStack align="start">
            <RadioButton
              label="your assigned variant"
              option={{ value: OFF_VALUE }}
              selectedOption={selectedOption}
              onChange={() => onChange(experimentId, OFF_VALUE)}
            />

            {/**
             * variantCollection is not expected to be empty in production
             * setting as there are validations on Contentful to prevent an
             * Experiment from being published if variantCollection is empty.
             * However, in draft state, Netlify preview sites could potentially
             * deploy while variantCollection is empty.
             *
             * TODO: Instead of displaying nothing, show error messages on
             * on screen for preview sites.
             */}
            {variantCollection?.map(({ variantId }) => {
              return (
                <RadioButton
                  key={variantId}
                  label={variantId}
                  option={{ value: variantId }}
                  selectedOption={selectedOption}
                  onChange={() => onChange(experimentId, variantId)}
                />
              );
            })}
          </VStack>
        </RadioGroup>
      </CardBody>
    </Card>
  );
};

const RadioButton = ({
  label,
  option,
  selectedOption,
  onChange,
  ...otherProps
}) => {
  return (
    <Box
      w="100%"
      p="1rem"
      border="1px"
      borderRadius="4px"
      borderColor={
        option.value === selectedOption.value ? "blue.400" : "neutral.200"
      }
      cursor="pointer"
      onClick={onChange}
      {...otherProps}
    >
      <Radio
        value={option.value}
        size="lg"
        isChecked={option.value === selectedOption.value}
      >
        <Text textStyle="body2">{label}</Text>
      </Radio>
    </Box>
  );
};

const CtaButtons = ({ onClose, onReset, disableReset = false }) => {
  return (
    <Flex
      gap="1rem"
      bgColor="neutral.white"
      px={HORIZONTAL_PADDING}
      py="1rem"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="outline"
        borderColor="neutral.300"
        borderRadius="4px"
        px="2rem"
        py="1rem"
        w="100%"
        h="100%"
        onClick={onClose}
      >
        Close
      </Button>
      <Button
        variant="unstyled"
        color="neutral.white"
        bgColor={AMPLITUDE_BRAND_COLORS.primary}
        borderColor="neutral.300"
        borderRadius="4px"
        px="2rem"
        py="1rem"
        w="100%"
        h="100%"
        _hover={{ bgColor: AMPLITUDE_BRAND_COLORS.primaryAlt }}
        onClick={onReset}
        isDisabled={disableReset}
        _disabled={{
          bgColor: "neutral.300",
          cursor: "default",
        }}
      >
        Reset options
      </Button>
    </Flex>
  );
};
