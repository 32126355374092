import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

import textStyles from "../text_styles";

import inputTheme from "./input";

const baseStyleIcon = defineStyle({
  width: "1.5rem",
  height: "100%",
  insetEnd: "1rem",
  position: "relative",
  color: "currentColor",
  fontSize: "1.5rem",
  _disabled: {
    opacity: 0.5,
  },
});

const variants = {
  brand: definePartsStyle({
    field: {
      ...textStyles.body0,
      borderBottom: "2px",
      borderColor: "marine.500",
      color: "marine.500",
      borderRadius: 0,
      paddingInlineStart: "0rem",
      paddingInlineEnd: "3rem",
      height: "3rem",
    },
    icon: {
      color: "marine.500",
      fontSize: "200%",
    },
  }),
  outline: () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { color, ...otherFieldsProps } = inputTheme.variants.outline.field;
    return definePartsStyle({
      field: {
        ...otherFieldsProps,
      },
    });
  },
};

const sizes = {
  xl: () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { color, ...otherFieldsProps } = inputTheme.baseStyle.field;
    return defineStyle({
      ...inputTheme.baseStyle,
      field: {
        ...otherFieldsProps,
      },
      icon: baseStyleIcon,
    });
  },
};

export default defineMultiStyleConfig({ variants, sizes });
