// These are links that we want to intercept to trigger a flow that is
// different from the usual behavior of just simply routing to the path.
export enum InterceptedLink {
  SIGN_UP = "/sign-up",
}

export const isSignUpLink = (link: string) => {
  if (!link) return false;
  return link.startsWith(InterceptedLink.SIGN_UP);
};
