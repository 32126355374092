export default {
  easing: {
    ease: "cubic-bezier(0.48, 0, 0.32, 1)",
    snap: "cubic-bezier(0.32, 0, 0, 1.12)",
    "ease-in": "cubic-bezier(0, 0, 0.1, 1)",
    "snap-in": "cubic-bezier(0, 0, 0, 1.2)",
    "ease-out": "cubic-bezier(0.8, 0, 1, 1)",
    "snap-out": "cubic-bezier(0.92, -0.16, 1, 1)",
  },
};
