import textures from "../textures";

const gradientTexture = textures.noise[256];

export default {
  marine: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, forest.600 19%, marine.400 90%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.8,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "linear(to-r,forest.600 4%, marine.400 59%, lunar.200 100%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.8,
      },
    },
    light: {
      colors: {
        backgroundImage: "linear(to-r, marine.300 19%, forest.100 100%)",
      },
    },
  },
  solar: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, tropic.600 19%, solar.400 88%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.8,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "linear(to-r, tropic.700 0%, solar.400 57%, forest.200 93%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.72,
      },
    },
    light: {
      colors: {
        backgroundImage: "linear(to-r, tropic.300 19%, solar.200 100%)",
      },
    },
  },
  lunar: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, lunar.500 10%, marine.300 89%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.8,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "linear(to-r, tropic.700 0%, lunar.400 58%, marine.300 94%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 1,
      },
    },
    light: {
      colors: {
        backgroundImage: "linear(to-r, lunar.300 19%, marine.200 100%)",
      },
    },
    subtle: {
      wash: {
        colors: {
          backgroundImage: "linear(218deg, tropic.400 -41.06%, #7760F9 55.93%)",
        },
      },
    },
  },
  forest: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, forest.600 10%, solar.300 100%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.64,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "linear(to-r, forest.700 0%, solar.400 59%, solar.200 100%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.64,
      },
    },
    light: {
      colors: {
        backgroundImage: "linear(to-r, forest.300 19%, solar.200 100%)",
      },
    },
  },
  tropic: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, marine.600 19%, tropic.400 100%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.56,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "linear(to-r, marine.700 0%, tropic.400 62%, solar.200 100%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.56,
      },
    },
    light: {
      colors: {
        backgroundImage: "linear(to-r, marine.300 19%, tropic.200 100%)",
      },
    },
  },
  stone: {
    default: {
      colors: {
        backgroundImage:
          "linear(to-r, marine.stone.default 14%, forest.stone.default 91%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.48,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "linear(to-r, neutral.800 0%, marine.stone.default 40%, forest.stone.default 91%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.48,
      },
    },
  },
  brand: {
    default: {
      colors: {
        backgroundImage: "linear(63deg, #08A77E 34.37%, #4EE0A7 100%)",
      },
    },
  },
  vulcan: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, lunar.700 0%, solar.400 100% )",
      },
      texture: {
        ...textures.noise[100],
        opacity: 0.16,
      },
    },
  },
  citrus: {
    default: {
      colors: {
        backgroundImage: "linear(to-r, #FF8B00 0%, #FFB900 100% )",
      },
      texture: {
        ...textures.noise[100],
        opacity: 0.16,
      },
    },
  },
};
