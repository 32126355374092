import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

import textStyles from "../text_styles";

const baseStyle = definePartsStyle({
  list: {
    py: "1.25rem",
    borderRadius: "2rem",
    border: "1px solid neutral.200",
    bg: "neutral.white",
    minW: "20.5rem",
    boxShadow:
      "0px 0px 1px 0px rgba(13, 13, 13, 0.32), 0px 16px 24px 0px rgba(13, 13, 13, 0.20)",
  },
  item: {
    ...textStyles.body0,
    px: "2.5rem",
    color: "neutral.800",
    my: "1.125rem",
  },
});

export default defineMultiStyleConfig({ baseStyle });
