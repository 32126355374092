// window and document are not defined in the gatsby build process
const isBrowser = typeof window !== "undefined";

export const isMobile = () => {
  return (
    isBrowser &&
    (/android/i.test(window.navigator.userAgent) ||
      (/iPhone/.test(window.navigator.userAgent) && !window.MSStream))
  );
};
