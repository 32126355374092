import { z } from "zod";

import { InvestorStatus } from "../lite_onboarding_modal_v2/enums";

import { ErrorMessage } from ".";

const trimString = (u: unknown) => (typeof u === "string" ? u.trim() : u);

export const zAddress1 = z.preprocess(
  trimString,
  z.string().min(1, ErrorMessage.Required),
);

// dont think we need a strict validation as it will be a dropdown
export const zCountryAlpha2Code = z.preprocess(
  trimString,
  z.string().min(1, ErrorMessage.Required),
);

export const zEmail = z
  .string()
  .min(1, ErrorMessage.Required)
  .email(ErrorMessage.InvalidEmail);

export const zFirstName = z.preprocess(
  trimString,
  z.string().min(1, ErrorMessage.Required),
);

export const zInvestorStatus = z
  .nativeEnum(InvestorStatus)
  .array()
  .min(1, ErrorMessage.Required);

export const zLastName = z.preprocess(
  trimString,
  z.string().min(1, ErrorMessage.Required),
);

export const zMiddleName = z.preprocess(trimString, z.string().optional());

export const zRequiredTrue = z.coerce.boolean().refine((v) => v === true, {
  message: ErrorMessage.Required,
});
