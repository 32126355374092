import textures from "../textures";

const gradientTexture = textures.noise[256];

const lightLunar = {
  colors: {
    backgroundImage:
      "radial-gradient(85.3% 126.4% at 71.12% -16.69%, rgba(86, 180, 232, 0.16) 10.39%, rgba(90, 83, 218, 0.16) 100%)",
  },
  texture: {
    ...gradientTexture,
    opacity: 1,
  },
};

export default {
  light: {
    lunar: lightLunar,
  },
  lunar: {
    light: lightLunar,
    default: {
      colors: {
        backgroundImage:
          "radial-gradient(141.42% 141.42% at 100% 0%, marine.300 9.77%, lunar.500 74.22%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.72,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "radial-gradient(141.42% 141.42% at 100% 0%, marine.300 10%, lunar.400 30%, tropic.700 90%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.72,
      },
    },
  },
  forest: {
    soft: {
      colors: {
        backgroundImage:
          "radial-gradient(141.42% 141.42% at 100% 0%, forest.300 0%, forest.500 36.33%, forest.600 82.81%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.8,
      },
    },
  },
  stone: {
    default: {
      colors: {
        backgroundImage:
          "radial-gradient(141.42% 141.42% at 100% 0%, forest.stone.default 28.65%, marine.stone.default 72.92%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.72,
      },
    },
    accent: {
      colors: {
        backgroundImage:
          "radial-gradient(141.42% 141.42% at 100% 0%, forest.stone.default 34.9%, marine.stone.default 67.71%, neutral.800 96.35%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.56,
      },
    },
  },
};
