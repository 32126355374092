import React, { useEffect } from "react";
import { Alert, Button, Image, Text } from "@chakra-ui/react";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { isPreview } from "../utils";

import "react-photo-view/dist/react-photo-view.css";

const isBrowser = typeof window !== "undefined";

const ReactPhotoView = isBrowser ? require("react-photo-view") : null;

const ContentfulImage = (props) => {
  const { data, lightbox = false, ...otherProps } = props;

  if (!data) return null;

  const { localFile } = data;

  const gatsbyImageData = localFile?.childImageSharp?.gatsbyImageData;
  const url = data.url ?? localFile?.publicURL;

  if (!gatsbyImageData && !url) return null;

  if (url.endsWith(".riv")) {
    return <ContentfulRive data={data} {...otherProps} />;
  }

  // if isPreview, use image url from data
  // since only url will be updated
  const image =
    !isPreview() && gatsbyImageData ? (
      // for non-svg images (jpg, png, etc.)
      <Image
        as={GatsbyImage}
        image={getImage(localFile)}
        alt={data.description}
        imgStyle={{ height: "100%", objectFit: "contain" }}
        {...otherProps}
      />
    ) : (
      // for svg images
      <Image src={url} alt={data.description} {...otherProps} />
    );

  // show alert if image description is missing in preview
  if (isPreview() && !data.description) {
    return (
      <>
        {image}
        <Alert status="warning" justifyContent="center">
          <Text>Missing image description</Text>
        </Alert>
      </>
    );
  }

  if (isBrowser && lightbox) {
    return (
      <ReactPhotoView.PhotoProvider
        bannerVisible={false}
        maskOpacity={0.9}
        photoClosable
      >
        <ReactPhotoView.PhotoView src={url}>
          {/* requires a component with onClick */}
          <Button variant="unstyled">{image}</Button>
        </ReactPhotoView.PhotoView>
      </ReactPhotoView.PhotoProvider>
    );
  } else {
    return image;
  }
};

// requires stateMachines to be set to "State Machine" in riv
const ContentfulRive = ({ data, alignment = Alignment.Center }) => {
  const url = data.url ?? data.localFile?.publicURL;
  const { rive, RiveComponent } = useRive({
    src: url,
    stateMachines: "State Machine",
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
    }),
  });

  useEffect(() => {
    if (rive) {
      rive.layout = new Layout({
        fit: Fit.Contain,
        alignment: alignment,
      });
    }
  }, [rive, alignment]);

  return <RiveComponent />;
};

export default ContentfulImage;
