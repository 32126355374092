export const PREPEND = "m-lite-onboarding-";

export enum SectionId {
  CountryOfResidence = "m-lite-onboarding-country-of-residence",
  Email = "m-lite-onboarding-email",
  InvestorStatus = "m-lite-onboarding-investor-status",
  PersonalInformation = "m-lite-onboarding-personal-information",
  Success = "m-lite-onboarding-success",
  TermsAndConditions = "m-lite-onboarding-terms-&-conditions",
  Waitlist = "m-lite-onboarding-waitlist",
}

// Order of the steps, used for the progress bar
export const STEPS = [
  SectionId.Email,
  SectionId.PersonalInformation,
  SectionId.CountryOfResidence,
  SectionId.InvestorStatus,
  SectionId.TermsAndConditions,
];

export enum InvestorStatus {
  UsQualifiedPurchaser = "usQualifiedPurchaser",
  UsQualifiedClient = "usQualifiedClient",
  UsAccreditedNetworth = "usAccreditedNetworth",
  UsAccreditedAnnualIncome = "usAccreditedAnnualIncome",
  UsAccreditedAnnualIncomeWithSpouse = "usAccreditedAnnualIncomeWithSpouse",
  UsAccreditedLicenses = "usAccreditedLicenses",
  None = "none",
}

// alpha2Code
export enum Country {
  US = "US",
}

export enum LiteOnboardingFields {
  // Email
  Email = "email",

  // PersonalInformation
  FirstName = "firstName",
  MiddleName = "middleName",
  LastName = "lastName",
  Citizenship = "citizenship",

  // CountryOfResidence
  StreetAddress1 = "streetAddress1",
  StreetAddress2 = "streetAddress2",
  City = "city",
  State = "state",
  PostalCode = "postalCode",
  Country = "country",
  IsAlsoMailing = "isAlsoMailing",

  // InvestorStatus
  InvestorStatus = "investorStatus",

  // TermsAndConditions
  HasAgreedEsign = "hasAgreedEsign",
  HasAgreedBrochureCrsTncPrivacy = "hasAgreedBrochureCrsTncPrivacy",
  HasAgreedIma = "hasAgreedIma",
  HasReadIma = "hasReadIma",
}
