import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: "1.125rem",
  },
  closeButton: {
    padding: "2rem",
    svg: {
      strokeWidth: "2",
      height: "1.25rem",
      width: "1.25rem",
    },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    light: {
      closeButton: {
        svg: {
          color: "neutral.600",
          stroke: "neutral.600",
        },
      },
    },
    dark: {
      closeButton: {
        svg: {
          color: "neutral.white",
          stroke: "neutral.white",
        },
      },
    },
  },
  sizes: {
    almostFull: definePartsStyle({
      dialog: {
        maxW: "95vw",
        minH: "95vh",
        my: "0",
      },
    }),
  },
  defaultProps: {
    variant: "light",
  },
});
