/** `__typename` query from gatsby */
export enum ContentfulTypename {
  Page = "ContentfulPage",
  Experiment = "ContentfulExperiment",
  Variant = "ContentfulVariant",
  UiSection = "ContentfulUiSection",
  UiText = "ContentfulUiText",
  UiButton = "ContentfulUiButton",
  UiFile = "ContentfulUiFile",
  Insider = "ContentfulInsider",
  Tooltip = "ContentfulTooltip",
  Investment = "ContentfulInvestment",
}

/** `__typename` retrieve from `useContentfulLiveUpdates`  */
export enum ContentfulLiveEditTypename {
  Page = "Page",
  Experiment = "Experiment",
  Variant = "Variant",
  UiSection = "Section",
  UiText = "UiText",
  UiButton = "UiButton",
  UiFile = "UiFile",
  Insider = "Insider",
  Tooltip = "Tooltip",
  Investment = "InvestmentYield",
}

export const CONTENTFUL_TYPE_LIVE_EDIT_MAP: Record<
  ContentfulTypename,
  ContentfulLiveEditTypename
> = {
  [ContentfulTypename.Page]: ContentfulLiveEditTypename.Page,
  [ContentfulTypename.Experiment]: ContentfulLiveEditTypename.Experiment,
  [ContentfulTypename.Variant]: ContentfulLiveEditTypename.Variant,
  [ContentfulTypename.UiSection]: ContentfulLiveEditTypename.UiSection,
  [ContentfulTypename.UiText]: ContentfulLiveEditTypename.UiText,
  [ContentfulTypename.UiButton]: ContentfulLiveEditTypename.UiButton,
  [ContentfulTypename.UiFile]: ContentfulLiveEditTypename.UiFile,
  [ContentfulTypename.Insider]: ContentfulLiveEditTypename.Insider,
  [ContentfulTypename.Tooltip]: ContentfulLiveEditTypename.Tooltip,
  [ContentfulTypename.Investment]: ContentfulLiveEditTypename.Investment,
};

export const CONTENTFUL_LIVE_EDIT_TYPE_MAP: Record<
  ContentfulLiveEditTypename,
  ContentfulTypename
> = {
  [ContentfulLiveEditTypename.Page]: ContentfulTypename.Page,
  [ContentfulLiveEditTypename.Experiment]: ContentfulTypename.Experiment,
  [ContentfulLiveEditTypename.Variant]: ContentfulTypename.Variant,
  [ContentfulLiveEditTypename.UiSection]: ContentfulTypename.UiSection,
  [ContentfulLiveEditTypename.UiText]: ContentfulTypename.UiText,
  [ContentfulLiveEditTypename.UiButton]: ContentfulTypename.UiButton,
  [ContentfulLiveEditTypename.UiFile]: ContentfulTypename.UiFile,
  [ContentfulLiveEditTypename.Insider]: ContentfulTypename.Insider,
  [ContentfulLiveEditTypename.Tooltip]: ContentfulTypename.Tooltip,
  [ContentfulLiveEditTypename.Investment]: ContentfulTypename.Investment,
};

export enum PageId {
  Home = "home",
  HomeOld = "home-old",
  Product = "product",
  Alts = "product-alts",
  Cash = "product-cash",
  HarvestTreasuries = "harvest-treasuries",
  StructuredOfferings = "product-structured-offerings",
  DirectIndexing = "product-direct-indexing",
  Wealthgen = "product-wealthgen",
  Careers = "careers",
  Wealthcare = "wealthcare",
  Paycheck = "paycheck",
  Podcast = "podcast",
  Disclosures = "disclosures",
  DisclosuresSg = "disclosures-sg",
  LegalPrivacyTerms = "legal-privacy-terms",
  LegalPrivacyTermsSg = "legal-privacy-terms-sg",
  TermsOfService = "terms-of-service",
  ClientRelationshipSummary = "client-relationship-summary",
  FirmBrochure = "firm-brochure",
  QuestionsForFinancialProfessional = "questions-for-financial-professional",
  PrivacyPolicy = "privacy-policy",
  InvestmentManagementAgreement = "investment-management-agreement",
  JobCandidatePrivacyPolicy = "job-candidate-privacy-policy",
  EsignConsent = "esign-consent",
  OpeningBell = "openingbell",
  GooglerBikeHelmets = "googler-bike-helmets",
  LiteOnboarding = "lite-onboarding",
}

export enum SiteId {
  Us = "us",
  Sg = "sg",
}

export enum SectionLayout {
  Awards = "awards",
  Companies = "companies",
  ContentImage = "content-image",
  Credentials = "credentials",
  DownloadWhitePaper = "download-white-paper",
  FamilyOffice = "family-office",
  Gallery = "gallery",
  HeroText = "hero-text",
  InsightsCarousel = "insights-carousel",
  InsightsCarouselAll = "insights-carousel-all",
  Join = "join",
  Marquee = "marquee",
  Masthead = "masthead",
  News = "news",
  NewsMarquee = "news-marquee",
  NewsletterSubscribe = "newsletter-subscribe",
  Pillars = "pillars",
  PodcastPlatformLinks = "podcast-platform-links",
  QuestionsAndAnswers = "questions-and-answers",
  Quotes = "quotes",
  SectionDivider = "section-divider",
  Table = "table",
  TalkToOurTeam = "talk-to-our-team",
  TeamCareers = "team-careers",
}

export enum SectionId {
  Awards = "shared-awards-section",
  Companies = "shared-companies-section",
  Credentials = "shared-credentials-section",
  CredentialsSg = "shared-credentials-sg-section",
  InsightsCarousel = "shared-insights-section",
  Join = "shared-join-section",
  NewsMarquee = "shared-news-marquee-section",
  PodcastPlatformLinks = "shared-podcast-platform-links",
}
