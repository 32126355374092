import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useStore = create(
  devtools(
    persist(
      (set, get) => ({
        isGated: () => !get().firstName || !get().lastName || !get().email,
        firstName: "",
        lastName: "",
        email: "",

        setEmail: (email) => set(() => ({ email })),
        unlockWhitepaper: ({ firstName, lastName, email }) =>
          set(() => {
            return {
              firstName,
              lastName,
              email,
            };
          }),
      }),
      {
        name: "arta-storage",
      },
    ),
  ),
);

export default useStore;
