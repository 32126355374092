import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const DoubleArrowRightIcon = createIcon({
  displayName: "DoubleArrowRightIcon",
  viewBox: "0 0 25 24",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        d="M7.17758 6L5.76758 7.41L10.3476 12L5.76758 16.59L7.17758 18L13.1776 12L7.17758 6Z"
        fill="currentColor"
      />
      <path
        d="M13.7676 6L12.3576 7.41L16.9376 12L12.3576 16.59L13.7676 18L19.7676 12L13.7676 6Z"
        fill="currentColor"
      />
    </svg>
  ),
});
