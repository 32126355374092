import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";

import ArtaCheckbox from "../arta_checkbox";

import { ConnectForm, useArtaFormContext, useTrackInputEntered } from ".";

export const ArtaFormCheckbox = ({ name, helpText, ...otherProps }) => {
  const { trackingProperties } = useArtaFormContext();
  return (
    <ConnectForm>
      {(methods) => {
        const { formState, getFieldState, control } = methods;
        const { isSubmitting } = formState;
        const fieldState = getFieldState(name, formState);
        const { isTouched, error } = fieldState;

        const showError = isTouched && error;

        useTrackInputEntered({ methods, trackingProperties, name });

        return (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <FormControl isInvalid={showError}>
                <ArtaCheckbox
                  isChecked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  isDisabled={isSubmitting}
                  {...otherProps}
                />
                {showError ? (
                  <FormErrorMessage>{error.message}</FormErrorMessage>
                ) : helpText ? (
                  <FormHelperText>{helpText}</FormHelperText>
                ) : (
                  // HACK: Keep the layout consistent when there is no help text, or when there is an error
                  <FormHelperText>&nbsp;</FormHelperText>
                )}
              </FormControl>
            )}
          />
        );
      }}
    </ConnectForm>
  );
};
