import React from "react";
import { Box, Button, Icon, useDisclosure } from "@chakra-ui/react";
import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { IconArrowRight } from "@tabler/icons-react";
import { Link } from "gatsby";

import { useProcessedPath } from "../hooks/useProcessedPath";

import {
  EventProperty,
  formatContentfulLiveUpdates,
  getTrackingProps,
  isAppLink,
  isExternalLink,
  isSignUpLink,
  Subcategory,
  trackClick,
  trackModalViewed,
} from "../utils";

import { AnalyticsLabel, AnalyticsLink } from "./analytics";
import ContentfulImage from "./contentful_image";
import LiteOnboardingModal from "./lite_onboarding_modal";

const ArtaButton = ({
  gaLabel,
  gaCategory,
  gaText,
  to,
  onClick,
  children,
  disableAutoTransform,
  trackingProperties,
  ...otherProps
}) => {
  const path = useProcessedPath(to);

  const rightIcon = <Icon as={IconArrowRight} />;
  const text = gaText ?? children.toString();

  const properties = {
    ...trackingProperties,
    gaLabel,
    gaCategory,
    to: path,
    text,
  };
  const trackingProps = getTrackingProps(properties);

  // We force our app links to open in the same tab because:
  // 1. Opening of native apps will only work if it opens up in the same tab
  //    for Chrome incognito.
  // 2. We want users to feel like they are still on the same platform when
  //    they click to navigate to the web app.
  const forceToOpenCurrentTab = isAppLink(path);
  const liteOnboardingModal = useDisclosure();

  const _trackClick = () => {
    trackClick(properties);
  };

  // to make most text visually centered vertically
  const textWithOffset = (
    <Box transform={disableAutoTransform ? "" : "translateY(6%)"}>
      {children}
    </Box>
  );

  if (isSignUpLink(path)) {
    return (
      <>
        <AnalyticsLabel gaLabel={gaLabel}>
          <Button
            {...trackingProps}
            rightIcon={rightIcon}
            onClick={(e) => {
              _trackClick();
              if (onClick) onClick(e);
              trackModalViewed({
                [EventProperty.Subcategory]: Subcategory.LiteOnboarding,
              });
              liteOnboardingModal.onOpen();
            }}
            {...otherProps}
          >
            {textWithOffset}
          </Button>
        </AnalyticsLabel>

        <LiteOnboardingModal
          to={path}
          isOpen={liteOnboardingModal.isOpen}
          onClose={liteOnboardingModal.onClose}
        />
      </>
    );
  }

  // not a link
  if (!path || onClick) {
    return (
      <AnalyticsLabel gaLabel={gaLabel}>
        <Button
          {...trackingProps}
          rightIcon={rightIcon}
          onClick={(e) => {
            _trackClick();
            if (onClick) onClick(e);
          }}
          {...otherProps}
        >
          {textWithOffset}
        </Button>
      </AnalyticsLabel>
    );
  }

  // internal link
  if (!isExternalLink(path)) {
    return (
      <AnalyticsLabel gaLabel={gaLabel}>
        <Button
          {...trackingProps}
          rightIcon={rightIcon}
          to={path}
          as={Link}
          onClick={_trackClick}
          {...otherProps}
        >
          {textWithOffset}
        </Button>
      </AnalyticsLabel>
    );
  }

  // external link
  return (
    <AnalyticsLabel gaLabel={gaLabel}>
      <Button
        {...trackingProps}
        rightIcon={rightIcon}
        href={path}
        as={"a"}
        target={forceToOpenCurrentTab ? "_self" : "_blank"}
        rel="noopener"
        onClick={_trackClick}
        {...otherProps}
      >
        {textWithOffset}
      </Button>
    </AnalyticsLabel>
  );
};

// convenient way to create a button with data from contentful
export const ContentfulButton = (props) => {
  const { data, imageProps, trackingProperties, ...otherProps } = props;
  const inspectorProps = useContentfulInspectorMode();
  const liveData = formatContentfulLiveUpdates({ data });
  if (!data) return null;

  const { url, longUrl, text, gaLabel, image } = liveData;
  const to = longUrl?.longUrl || url;

  if (image) {
    const imageWidget = <ContentfulImage data={image} {...imageProps} />;
    return (
      <AnalyticsLink
        data-sentry-unmask
        {...inspectorProps({
          entryId: data.contentful_id,
          fieldId: "image",
        })}
        to={to}
        gaCategory="image"
        gaLabel={gaLabel}
        trackingProperties={trackingProperties}
        {...otherProps}
      >
        {imageWidget}
      </AnalyticsLink>
    );
  }
  return (
    <ArtaButton
      data-sentry-unmask
      {...inspectorProps({
        entryId: data.contentful_id,
        fieldId: "text",
      })}
      to={to}
      gaLabel={gaLabel}
      trackingProperties={trackingProperties}
      {...otherProps}
    >
      {text}
    </ArtaButton>
  );
};

export default ArtaButton;
