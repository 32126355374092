import { track } from "@amplitude/analytics-browser";

import { Event, isExternalLink } from "..";

// window and document are not defined in the gatsby build process
const isBrowser = typeof window !== "undefined";

export const getTrackingProps = ({ gaLabel }) => {
  return {
    "data-event-label": gaLabel,
  };
};

export const trackClick = (props) => {
  const {
    gaLabel,
    gaCategory, // button, link
    to,
    text,
    ...otherProperties
  } = props;

  if (isBrowser) {
    const pageProperties = getPageProperties();
    if (isExternalLink(to)) {
      // ====== external ======
      // amplitude
      track(Event.LinkClicked, {
        ...pageProperties,
        event_category: gaCategory ?? "link",
        event_label: gaLabel ?? "outbound",
        to,
        text,
        ...otherProperties,
      });
      // ga
      if (window.gtag && to) {
        window.gtag("event", "click", {
          event_category: "outbound",
          event_label: to,
        });
      }
    } else {
      // ====== internal ======
      // amplitude
      track(Event.ButtonClicked, {
        ...pageProperties,
        event_category: gaCategory ?? "button",
        event_label: gaLabel,
        to,
        text,
        ...otherProperties,
      });
      // ga
      if (window.gtag) {
        const preLabel = formatPathname(location.pathname);
        window.gtag("event", "click", {
          event_category: gaCategory ?? "button",
          event_label: `${preLabel}_${gaLabel}`,
        });
      }
    }
  }
};

export const trackInfoSubmitted = (properties) => {
  const pageProperties = getPageProperties();
  track(Event.InfoSubmitted, {
    ...pageProperties,
    ...properties,
  });
};

export const trackInputEntered = (properties) => {
  const pageProperties = getPageProperties();
  track(Event.InputEntered, {
    ...pageProperties,
    ...properties,
  });
};

export const trackModalViewed = (properties) => {
  const pageProperties = getPageProperties();
  track(Event.ModalViewed, {
    ...pageProperties,
    ...properties,
  });
};

export const trackPdfDownloaded = (properties) => {
  const pageProperties = getPageProperties();
  track(Event.PdfDownloaded, {
    ...pageProperties,
    ...properties,
  });
};

// reference: https://github.com/amplitude/Amplitude-TypeScript/blob/main/packages/plugin-page-view-tracking-browser/src/page-view-tracking.ts
export const getPageProperties = () => {
  return {
    "[Amplitude] Page Domain":
      (typeof location !== "undefined" && location.hostname) || "",
    "[Amplitude] Page Location":
      (typeof location !== "undefined" && location.href) || "",
    "[Amplitude] Page Path":
      (typeof location !== "undefined" && location.pathname) || "",
    "[Amplitude] Page Title":
      (typeof document !== "undefined" && document.title) || "",
    "[Amplitude] Page URL":
      (typeof location !== "undefined" && location.href.split("?")[0]) || "",
  };
};

const formatPathname = (str) => {
  const result = str
    .split("/")
    .filter((v) => v !== "")
    .join("_");

  if (result === "") {
    return "home";
  }
  return result;
};

// append ?a to the url to show analytics label
export const showAnalyticsLabel = () => {
  if (!isBrowser) return false;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.has("a");
};
