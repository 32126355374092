import React from "react";
import { Link, Text } from "@chakra-ui/react";

import { useSiteUrl } from "../templates/contentful_page/context";

const EmailDisclosuresText = ({ ...otherProps }) => {
  const privacyPolictyUrl = useSiteUrl("/privacy-policy");
  const termsOfServiceUrl = useSiteUrl("/terms-of-service");
  return (
    <Text data-sentry-unmask textStyle="disclosures" {...otherProps}>
      By providing your information, you agree to our{" "}
      <Link
        target="_blank"
        rel="noopener"
        href={termsOfServiceUrl}
        color="marine.500"
      >
        Term of Service
      </Link>{" "}
      and{" "}
      <Link
        target="_blank"
        rel="noopener"
        href={privacyPolictyUrl}
        color="marine.500"
      >
        Privacy Policy
      </Link>{" "}
      and elect to receive email communications from Arta.
    </Text>
  );
};

export default EmailDisclosuresText;
