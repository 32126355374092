import React, { useState } from "react";
import {
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/browser";
import { IconCircleCheck } from "@tabler/icons-react";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import { z } from "zod";

import {
  constructAppLinkUrl,
  EventProperty,
  formatContentfulSectionData,
  maybeConstructAttributionProto,
  Subcategory,
} from "../utils";

import { useAttributionDataContext } from "../contexts";
import useStore from "../stores";
import { usePageDataOrNull } from "../templates/contentful_page/context";

import {
  ArtaContentfulSubmitButton,
  ArtaForm,
  ArtaInput,
  ArtaSubmitErrorMessage,
  setSubmitError,
  useArtaForm,
  zEmail,
} from "./arta_form";
import ArtaButton, { ContentfulButton } from "./button";
import ContentfulText from "./contentful_text";
import EmailDisclosuresText from "./email_disclosures_text";
/**
 * Lite onboarding modal data are handled in Contentful in this structure:
 *
 * Modal container           (Type: UiSection)
 * ↳ Input modal data        (Type: UiSection)
 * ↳ Confirmation modal data (Type: UiSection)
 * ↳ Error modal data        (Type: UiSection)
 *
 * The default data is in the modal container with the ID "m-lite-onboarding".
 * Any pages that requires custom copy for the modals should be in the format
 * of "<pageId>-m-lite-onboarding".
 */

const MODAL_ID = "m-lite-onboarding";

const LiteOnboardingModal = ({ to, isOpen, onClose }) => {
  const [email] = useState(useStore((state) => state.email));

  const methods = useArtaForm({
    defaultValues: { email },
    resolver: zodResolver(z.object({ email: zEmail })),
  });
  const {
    reset,
    clearErrors,
    formState: { isSubmitSuccessful },
  } = methods;

  const pageData = usePageDataOrNull();
  const uniqueId = `${pageData?.pageId}-${MODAL_ID}`;

  const data = useData();
  const defaultData = data.find(({ sectionId }) => sectionId === MODAL_ID);
  const uniqueData = data.find(({ sectionId }) => sectionId === uniqueId);
  const formattedData = formatContentfulSectionData(
    uniqueData ? uniqueData.items : defaultData.items,
  );
  const [inputData, confirmData] = formattedData.sections;

  const handleClose = () => {
    onClose();
    clearErrors();
    if (isSubmitSuccessful) {
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px={{ base: "3rem", md: "5rem" }} py="5rem">
          {isSubmitSuccessful ? (
            <ConfirmationContent data={confirmData} onClose={handleClose} />
          ) : (
            <InputContent to={to} data={inputData} methods={methods} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const InputContent = ({ to, data, methods }) => {
  const { texts, buttons } = data;
  const [title, desc] = texts;
  const [button] = buttons;

  const { attributionData, urlParams } = useAttributionDataContext();
  const setEmail = useStore((state) => state.setEmail);

  // The app link might come with existing query parameters.
  // Merging these existing query parameters with attribution parameters.
  const getQueryParams = (to) => {
    if (!to) return new URLSearchParams();

    const url = constructAppLinkUrl(to);
    const existingParams = new URLSearchParams(url.search);
    for (const [key, value] of urlParams.entries()) {
      existingParams.append(key, value);
    }
    return existingParams;
  };

  const onSubmit = ({ data }) => {
    const startSignInUrl = `${process.env.GATSBY_FIREBASE_ENDPOINT_URL}/startSignInNg`;
    const subscribeNewsletterUrl = `${process.env.GATSBY_FIREBASE_ENDPOINT_URL}/subscribeNewsletter`;

    return axios
      .post(startSignInUrl, {
        email: data.email,
        queryParams: getQueryParams(to).toString(),
        marketingAttributionMetadata:
          maybeConstructAttributionProto(attributionData),
      })
      .then(() => {
        setEmail(data.email);

        // Subscribe user to newsletter
        try {
          axios.post(subscribeNewsletterUrl, { email: data.email });
        } catch (error) {
          // We don't have a dedicated error flow for when subscribing
          // to newsletters fails, so do nothing except for logging
          // error event for now
          Sentry.captureException(error, {
            tags: {
              endpointUrl: subscribeNewsletterUrl,
            },
          });
          setSubmitError(methods);
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          tags: {
            endpointUrl: startSignInUrl,
          },
        });
        setSubmitError(methods);
      });
  };

  return (
    <ArtaForm
      methods={methods}
      trackingProperties={{
        [EventProperty.Subcategory]: Subcategory.LiteOnboarding,
      }}
      onSubmit={onSubmit}
    >
      <ContentfulText
        data={title}
        textStyle="display1"
        color="neutral.800"
        textAlign="center"
      />
      <ContentfulText
        data={desc}
        textStyle="body2"
        color="neutral.600"
        textAlign="center"
        mt="0.75rem"
        mb="3rem"
      />
      <VStack spacing="1rem">
        <ArtaInput
          autoFocus
          name="email"
          placeholder="your email address"
          type="email"
          autoComplete="email"
        />
        <EmailDisclosuresText textStyle="disclosures" color="neutral.600" />
        <ArtaContentfulSubmitButton
          mt="2.5rem"
          data={button}
          w="100%"
          rightIcon={null}
        />
        <ArtaSubmitErrorMessage />
        <ArtaButton
          variant="link"
          textStyle="body2"
          to={constructAppLinkUrl(to).toString()}
          gaLabel="lite_onboarding_already_have_account"
          rightIcon={null}
          mt="0.5rem"
        >
          I already have an account
        </ArtaButton>
      </VStack>
    </ArtaForm>
  );
};

const ConfirmationContent = ({ data, onClose }) => {
  const { texts, buttons } = data;
  const [title, desc] = texts;
  const [button] = buttons;

  return (
    <VStack spacing="0.75rem" textAlign="center">
      <Icon as={IconCircleCheck} boxSize="5.87rem" color="forest.400" />

      <ContentfulText data={title} textStyle="display1" color="neutral.800" />
      <ContentfulText data={desc} textStyle="body2" color="neutral.600" />

      <ContentfulButton
        data={button}
        size="xsmall"
        onClick={onClose}
        rightIcon={null}
        w="100%"
        mt="2.5rem"
      />
    </VStack>
  );
};

const useData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulUiSection(
        filter: {
          node_locale: { eq: "en-US" }
          sectionId: { regex: "/m-lite-onboarding$/" }
        }
      ) {
        nodes {
          ...ContentfulUiSectionRecursive
        }
      }
    }
  `);

  return data.allContentfulUiSection.nodes;
};

export default LiteOnboardingModal;
