export default {
  matrix: {
    background: 'url("/textures/texture-matrix.png") repeat',
    backgroundSize: "0.84375rem",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  noise: {
    100: {
      background: 'url("/textures/texture-noise-100.png") repeat',
      backgroundSize: "6.25rem",
      height: "100%",
      width: "100%",
      position: "absolute",
    },
    256: {
      background: 'url("/textures/texture-noise-256.png") repeat',
      backgroundSize: "16rem",
      height: "100%",
      width: "100%",
      position: "absolute",
    },
    heavy: {
      dark: {
        500: {
          background:
            'url("/textures/texture-noise-heavy-dark-500.png") repeat',
          backgroundSize: "31.25rem",
          height: "100%",
          width: "100%",
          position: "absolute",
        },
      },
    },
  },
};
