export default {
  red: {
    500: "#d34f92", // tropic.500 used for error
  },
  neutral: {
    25: "#fcfcfc",
    50: "#f5f5f5",
    100: "#eeeeee",
    200: "#e0e0e0",
    300: "#bdbdbd",
    400: "#9e9e9e",
    500: "#757575",
    600: "#616161",
    700: "#424242",
    800: "#323232",
    900: "#242424",
    950: "#171717",
    black: "#000000",
    white: "#ffffff",
  },
  marine: {
    50: "#e9fbff",
    100: "#c8f2fd",
    200: "#a9e9fb",
    300: "#77d2f4",
    400: "#56b4e8",
    500: "#4091d8",
    600: "#306dc1",
    700: "#2550a4",
    800: "#1c3a84",
    900: "#152b66",
    stone: {
      default: "#45515c",
      light: "#496c7a",
      dark: "#37393d",
    },
  },
  forest: {
    50: "#e9fff5",
    100: "#cbf3e3",
    200: "#b0e7d3",
    300: "#7ecfba",
    400: "#54b6a5",
    500: "#349e93",
    600: "#1f8680",
    700: "#126e6b",
    800: "#0b5555",
    900: "#073d3d",
    stone: {
      default: "#596661",
      light: "#728f7e",
      dark: "#3a3d3d",
    },
  },
  solar: {
    50: "#fdffe3",
    100: "#fef7bf",
    200: "#fde09c",
    300: "#f9ae76",
    400: "#f38460",
    500: "#e96551",
    600: "#d74e45",
    700: "#bb3c39",
    800: "#942d2c",
    900: "#5c1b1b",
  },
  lunar: {
    50: "#f5eeff",
    100: "#e7dafe",
    200: "#d7c6fd",
    300: "#b4a1f9",
    400: "#9281f4",
    500: "#7368ea",
    600: "#5a53da",
    700: "#4440bf",
    800: "#31309b",
    900: "#212170",
  },
  tropic: {
    50: "#ffeefc",
    100: "#fdd1f1",
    200: "#fab4e4",
    300: "#f285c7",
    400: "#e566ab",
    500: "#d34f92",
    600: "#b83e79",
    700: "#972f61",
    800: "#731b45",
    900: "#52112b",
  },
  coldstone: {
    50: "#f2fbff",
    100: "#e5eef3",
    200: "#d7e1e7",
    300: "#bec8cf",
    400: "#a4aeb6",
    500: "#8c959e",
    600: "#747d86",
    700: "#5d646e",
    800: "#474d55",
    900: "#32363d",
  },
  warmstone: {
    50: "#f3f9f5",
    100: "#e6ede9",
    200: "#d9e1dd",
    300: "#c0cac5",
    400: "#a8b2ae",
    500: "#909b96",
    600: "#788380",
    700: "#626c69",
    800: "#4b5552",
    900: "#363d3c",
  },
  accent: {
    altPrimary: "#46f0bd",
  },
};
