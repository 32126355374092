import { removeBlankAttributes } from "../utils";

import { ContentfulTypename } from "../templates/contentful_page/enums";

import { getVariantItems } from "./amplitude-experiments";

export const formatContentfulPageData = (data, experiment, previewVariants) => {
  return {
    ...data,
    ...formatContentfulSectionData(data.sections, experiment, previewVariants),
  };
};

export const formatContentfulSectionData = (
  itm,
  experiment,
  previewVariants,
) => {
  const items = itm || [];
  const buttons = [];
  const files = [];
  const insiders = [];
  const sections = [];
  const texts = [];
  const tooltips = [];
  const investments = [];

  const appendItem = (item) => {
    const { items: childItems } = item;
    switch (item.__typename) {
      case ContentfulTypename.UiSection:
        sections.push({
          ...item,
          config: removeBlankAttributes(item.config || {}),
          ...formatContentfulSectionData(
            childItems,
            experiment,
            previewVariants,
          ),
        });
        break;
      case ContentfulTypename.UiText:
        texts.push(item);
        break;
      case ContentfulTypename.UiButton:
        buttons.push(item);
        break;
      case ContentfulTypename.UiFile:
        files.push(item);
        break;
      case ContentfulTypename.Insider:
        insiders.push(item);
        break;
      case ContentfulTypename.Tooltip:
        tooltips.push(item);
        break;
      case ContentfulTypename.Investment:
        investments.push(item);
        break;
      default:
        console.error("unknown item type:", item.__typename);
    }
  };

  items.forEach((item) => {
    if (item.__typename === ContentfulTypename.Experiment) {
      // If item is an Experiment, resolve which variant to display to user. A
      // variant contains a list of items, so append each item into their
      // corresponding data lists.

      // variantCollection is not expected to be empty in production setting as
      // there are validations on Contentful to prevent an Experiment from being
      // published if variantCollection is empty. However, in draft state, Netlify
      // preview sites could potentially deploy while variantCollection is empty.
      // TODO: Instead of doing early return and displaying nothing, show error
      //       messages on screen for preview sites.
      if (!item.variantCollection) return;

      const items = getVariantItems(item, experiment, previewVariants);
      items.forEach((item) => appendItem(item));
    } else {
      // Otherwise, simply append the single item into its corresponding data
      // list as per normal.
      appendItem(item);
    }
  });

  return {
    items,
    buttons,
    files,
    insiders,
    sections,
    texts,
    tooltips,
    investments,
  };
};
