import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const DoubleCircleIcon = createIcon({
  displayName: "DoubleCircleIcon",
  viewBox: "0 0 64 65",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" fill="none">
      <circle
        cx="24.696"
        cy="25.696"
        r="14.696"
        stroke="currentColor"
        strokeWidth="4"
      />
      <circle
        cx="39.304"
        cy="40.304"
        r="14.696"
        stroke="currentColor"
        strokeWidth="4"
      />
    </svg>
  ),
});
