const PRODUCTION_APP_URL = "https://app.artafinance.com";

const appLinks = [
  // Hardcoding production app url as the urls from Contentful will always be
  // production app url only and we want these items to have the correct
  // behavior on non-production sites.
  PRODUCTION_APP_URL,
  process.env.GATSBY_APP_URL,
  process.env.GATSBY_APP_STORE_URL,
  process.env.GATSBY_PLAYSTORE_STORE_URL,
];

export const isAppLink = (link) => {
  return link ? appLinks.some((appLink) => link.startsWith(appLink)) : false;
};

export const isWebAppLink = (link) => {
  return link
    ? link.startsWith(PRODUCTION_APP_URL) ||
        link.startsWith(process.env.GATSBY_APP_URL)
    : false;
};
