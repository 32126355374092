import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

import textStyles from "../../../@chakra-ui/gatsby-plugin/text_styles";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const numericStyles = defineStyle({
  "&[data-is-numeric=true]": {
    textAlign: "end",
  },
});

const variants = {
  brand: definePartsStyle((props) => {
    const { borderTopRadius, borderRadius: br, size } = props;
    const border = "1px solid";
    const borderColor = "neutral.200";
    const borderRadius = size == "lg" ? "2rem" : { sm: "0", md: "1.5rem" };

    const borderTopLeftRadius = borderTopRadius ?? br ?? borderRadius;
    const borderTopRightRadius = borderTopRadius ?? br ?? borderRadius;
    const borderBottomLeftRadius = br ?? borderRadius;
    const borderBottomRightRadius = br ?? borderRadius;

    return {
      table: {
        border,
        borderColor,
        borderRadius,
        borderCollapse: "separate",
        borderTop: "none",
        borderSpacing: 0,
      },
      thead: {
        borderCollapse: "separate",
        "&:first-of-type tr:first-of-type th:first-of-type": {
          borderTopLeftRadius,
        },
        "&:first-of-type tr:first-of-type th:last-child": {
          borderTopRightRadius,
        },
        "&:last-child tr:last-child th:first-of-type": {
          borderBottomLeftRadius,
        },
        "&:last-child tr:first-of-type th:first-of-type": {
          borderBottomRightRadius,
        },
      },
      tbody: {
        "&:last-child tr:last-child td:first-of-type": {
          borderBottomLeftRadius,
        },
        "&:last-child tr:last-child td:last-child": {
          borderBottomRightRadius,
        },
        "& td:not(:last-child)": {
          borderRight: border,
          borderColor,
        },
      },
      th: {
        color: "neutral.white",
        bgColor: "neutral.950",
        textTransform: "none",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        ...numericStyles,
      },
      td: {
        borderTop: border,
        borderColor,
        ...numericStyles,
      },
    };
  }),
};

const sizes = {
  lg: definePartsStyle({
    th: {
      px: "2.5rem",
      py: "1.88rem",
      ...textStyles.headline5Em,
    },
    td: {
      px: "2.5rem",
      py: { base: "1.25rem", "2xl": "2.5rem" },
      ...textStyles.body0,
    },
  }),
  md: definePartsStyle({
    th: {
      px: { base: "1.8rem", md: "2.5rem" },
      py: { base: "2.3rem", md: "3rem" },
      ...textStyles.headline4Em,
    },
    td: {
      px: { base: "1.8rem", md: "2.5rem" },
      py: { base: "1.4rem", "2xl": "2.5rem" },
      ...textStyles.body1,
    },
  }),
};

export default defineMultiStyleConfig({
  variants,
  sizes,
});
