import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(enLocale);

const alpha2Codes = countries.getAlpha2Codes();

// in alpha2Code
export enum Country {
  CN = "CN",
  IR = "IR",
  TW = "TW",
  US = "US",
}

const USE_ALIAS_MAP = {
  [Country.CN]: true,
  [Country.IR]: true,
  [Country.TW]: true,
};
interface ICountries {
  [alpha2Code: string]: {
    name: string | undefined;
    alpha2Code: string;
  };
}

const countriesMap = Object.keys(alpha2Codes).reduce<ICountries>(
  (acc, alpha2Code) => {
    const select = USE_ALIAS_MAP[alpha2Code] ? "alias" : "official";
    const name = countries.getName(alpha2Code, "en", { select });
    return {
      ...acc,
      [alpha2Code]: {
        name,
        alpha2Code,
      },
    };
  },
  {},
);

export default countriesMap;
