// window and document are not defined in the gatsby build process
const isBrowser =
  typeof window !== "undefined" && typeof navigator !== "undefined";

export function isTouchedEnabled() {
  return (
    isBrowser &&
    ("ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0)
  );
}
