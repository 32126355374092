// reference: https://react-hook-form.com/advanced-usage#ConnectForm

import React, { createContext, useContext } from "react";
import { Form, FormProvider } from "react-hook-form";
import * as Sentry from "@sentry/gatsby";

import { trackInfoSubmitted } from "../../utils";

import { setSubmitError } from ".";

export const ArtaForm = ({
  methods,
  children,
  onSubmit,
  trackingProperties,
  // "marketing_website_info submitted" will be fired onSubmit unless disabled
  disableTrackingOnSubmit = false,
  ...otherProps
}) => {
  return (
    <FormProvider {...methods} {...otherProps}>
      <ArtaFormContext.Provider value={{ trackingProperties }}>
        <Form
          onSubmit={async (value) => {
            const { event } = value;
            event.preventDefault();

            if (!disableTrackingOnSubmit) {
              trackInfoSubmitted(trackingProperties);
            }

            try {
              return await onSubmit(value);
            } catch (error) {
              Sentry.captureException(error);
              setSubmitError(methods);
            }
          }}
        >
          {children}
        </Form>
      </ArtaFormContext.Provider>
    </FormProvider>
  );
};

const ArtaFormContext = createContext(null);

export const useArtaFormContext = () => useContext(ArtaFormContext);
