import React from "react";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { getImage } from "gatsby-plugin-image";
import getYouTubeID from "get-youtube-id";

import { AnalyticsLink } from "../../components/analytics";
import ContentfulImage from "../../components/contentful_image";

import {
  getRichTextPreviewMaps,
  InterceptedLink,
  renderContentfulRichText,
} from "../";

export const contentfulToInsider = (contentfulInsider) => {
  const {
    id,
    slug,
    title,
    subtitle,
    date,
    colorScheme,
    series,
    billboardImage,
    thumbnail,
    content,
    fields,
    excerpt,
    narrow,
    featured,
  } = contentfulInsider;

  const timeToRead = fields?.timeToRead ?? 0;

  const previewMaps = getRichTextPreviewMaps(content?.links);

  const body = !content
    ? null
    : renderContentfulRichText(content, richTextOptions(previewMaps));

  var footnotes = [];

  // live preview
  if (content?.links) {
    footnotes = content.links.entries.inline
      .filter((ref) => ref.__typename === "Footnote")
      .sort((a, b) => Number(a.number) - Number(b.number));
    // gatsby
  } else if (content?.references) {
    footnotes = content.references
      .filter((ref) => ref.__typename === "ContentfulFootnote")
      .sort((a, b) => Number(a.number) - Number(b.number));
  }

  return {
    id: id,
    slug: slug,
    link: "/insights/" + slug,
    title: title,
    subtitle: subtitle,
    date: date,
    colorScheme: colorScheme ?? "stone",
    series: series ?? "Draft",
    billboardImage: getImage(billboardImage?.localFile),
    thumbnail: getImage(thumbnail?.localFile),
    imageCenterY: "50%",
    timeToRead: timeToRead,
    excerpt: excerpt,
    narrow: narrow,
    body: body,
    featured,
    footnotes: renderFootnotes(footnotes, previewMaps),
  };
};

const richTextOptions = (previewMaps = {}) => {
  const { assetMap, entryMap } = previewMaps;

  return {
    renderMark: {
      [MARKS.CODE]: (text) => {
        if (text === "[member]") {
          return (
            <span className="button-link-wrapper">
              <AnalyticsLink
                className="button-link"
                to={InterceptedLink.SIGN_UP}
              >
                <img className="member" src="/insights/door-join-cta.svg" />
                BECOME A<br />
                MEMBER
              </AnalyticsLink>
            </span>
          );
        }
        return text;
      },
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // ================= LIVE PREVIEW =================
        const entry = assetMap.get(node.data.target.sys.id);
        if (entry) {
          return <ContentfulImage data={entry} lightbox />;
        }

        // ================= GATSBY =================
        return <ContentfulImage data={node.data.target} lightbox />;
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // ================= LIVE PREVIEW =================
        const entry = entryMap.get(node.data.target.sys.id) || {};
        if (entry?.__typename === "Tooltip") {
          return (
            <Tooltip
              text={entry.text}
              tooltip={renderContentfulRichText(
                entry.content,
                richTextOptions(previewMaps),
              )}
            />
          );
        } else if (entry?.__typename === "Footnote") {
          return <Footnote number={entry?.number} />;
        }

        // ================= GATSBY =================
        const { content, number, text, __typename } = node.data.target;
        if (__typename === "ContentfulTooltip") {
          return (
            <Tooltip
              text={text}
              tooltip={renderContentfulRichText(content, richTextOptions())}
            />
          );
        } else if (__typename === "ContentfulFootnote") {
          return <Footnote number={number} />;
        }

        return null;
      },
      [INLINES.HYPERLINK]: (node) => {
        const link = node.data.uri;
        const { value } = node.content[0];
        const youtubeId = getYouTubeID(link);
        if (youtubeId) {
          return (
            <section className="video-container">
              <iframe
                className="video"
                title={`https://youtube.com/embed/${youtubeId}`}
                src={`https://youtube.com/embed/${youtubeId}`}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </section>
          );
        }

        // "Become A Member" button with custom link
        if (value === "[member-image]") {
          return (
            <span className="button-link-wrapper">
              <AnalyticsLink className="button-link" to={link}>
                <img className="member" src="/insights/door-join-cta.svg" />
                BECOME A<br />
                MEMBER
              </AnalyticsLink>
            </span>
          );
        }

        // anchor links
        if (link.startsWith("#")) {
          return <AnalyticsLink to={link}>{value}</AnalyticsLink>;
        }
        // internal links
        if (link.startsWith(process.env.GATSBY_BASE_URL)) {
          return (
            <AnalyticsLink to={link.replace(process.env.GATSBY_BASE_URL, "")}>
              {value}
            </AnalyticsLink>
          );
        }

        return <AnalyticsLink to={link}>{value}</AnalyticsLink>;
      },
    },
  };
};

const renderFootnotes = (footnotes, maps) => {
  if (!footnotes.length) return null;
  return (
    <>
      <h3>Footnotes</h3>
      <div className="footnotes">
        <ol>
          {footnotes.map((f) => {
            const { number, content } = f;
            return (
              <li
                key={number}
                id={`fn-${number}`}
                className="footnote-list-item"
              >
                <span className="footnote-paragraph">
                  {renderContentfulRichText(content, maps)}
                </span>
                <a href={`#fnref-${number}`} className="footnote-backref">
                  ↩
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
};

const Tooltip = ({ text, tooltip }) => {
  return (
    <span className="tooltip">
      <span className="tooltip-content">{tooltip}</span>
      {text}
    </span>
  );
};

const Footnote = ({ number }) => {
  return (
    <sup id={`fnref-${number}`}>
      <a href={`#fn-${number}`} className="footnote-ref">
        {number}
      </a>
    </sup>
  );
};
