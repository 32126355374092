import textures from "../textures";

const gradientTexture = textures.noise[256];

export default {
  lunar: {
    colors: {
      backgroundImage:
        "radial-gradient(85.3% 126.4% at 71.12% -16.69%, marine.400 10.39%, lunar.600 84.15%)",
    },
    texture: {
      ...gradientTexture,
      opacity: 0.8,
    },
  },
  stone: {
    linear: {
      colors: {
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(90deg, #323232 0%, #45515C 40.1%, #596661 90.63%)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.24,
      },
    },
  },
};
