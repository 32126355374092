import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const LockIcon = createIcon({
  displayName: "LockIcon",
  viewBox: "0 0 96 97",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="97" fill="none">
      <g stroke="currentColor" strokeWidth="8" clipPath="url(#a)">
        <rect width="68" height="56" x="14" y="37" rx="12" />
        <path d="M62 37h4V23c0-9.941-8.059-18-18-18s-18 8.059-18 18v14h32Z" />
        <circle cx="47.5" cy="64.5" r="11.5" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .828h96v96H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
});
