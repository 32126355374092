import { InterceptedLink } from "./links";

export const constructAppLinkUrl = (to?: string | null): URL => {
  if (!process.env.GATSBY_APP_URL) {
    throw new Error("App url not available in build.");
  }

  let path = to || "";

  // The path could be an intercepted link which is not meant to be an actual
  // route. If is an intercepted link, remove the intercepted link route.
  const interceptedLinks = Object.values(InterceptedLink);
  for (let i = 0; i < interceptedLinks.length; i++) {
    const regex = new RegExp(`^${interceptedLinks[i]}`);
    if (regex.test(path)) {
      path = path.replace(regex, "");
      break;
    }
  }

  try {
    return new URL(path, process.env.GATSBY_APP_URL);
  } catch {
    // Is potentially an invalid argument, so return just the app url
    return new URL(process.env.GATSBY_APP_URL);
  }
};
