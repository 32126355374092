import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CircleDotIcon = createIcon({
  displayName: "CircleDotIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
    >
      <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
      <circle cx="12" cy="12" r="4" fill="currentColor" />
    </svg>
  ),
});
