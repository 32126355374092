const isStaging = process.env.GATSBY_STAGING === "true";

const isProduction = process.env.NODE_ENV === "production" && !isStaging;

const isDevelopment = process.env.NODE_ENV === "development";

const environment = isProduction
  ? "production"
  : isStaging
    ? "staging"
    : "development";

const isSentryEnabled = process.env.GATSBY_SENTRY_ENABLED === "true";

// have to use module.exports instead of export default
// because this file is being required in gatsby-config.js and gatsby-node.js
module.exports = {
  environment,
  isDevelopment,
  isProduction,
  isSentryEnabled,
  isStaging,
};
