import React from "react";
import { getDeviceId, getSessionId, init } from "@amplitude/analytics-browser";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import * as Sentry from "@sentry/gatsby";

import { environment, Event, isSentryEnabled } from "./src/utils";

import {
  AmplitudeExperimentProvider,
  AttributionDataProvider,
} from "./src/contexts";

import "@contentful/live-preview/style.css";

export const wrapRootElement = ({ element }) => {
  const enablePreview = process.env.GATSBY_CONTENTFUL_LIVE_PREVIEW === "true";

  return (
    <AmplitudeExperimentProvider>
      <ContentfulLivePreviewProvider
        locale="en-US"
        enableInspectorMode={enablePreview}
        enableLiveUpdates={enablePreview}
      >
        <AttributionDataProvider>{element}</AttributionDataProvider>
      </ContentfulLivePreviewProvider>
    </AmplitudeExperimentProvider>
  );
};

export const onInitialClientRender = () => {
  if (isSentryEnabled) {
    Sentry.init({
      dsn: process.env.GATSBY_SENTRY_DSN,

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      // release: "marketing-website@1.1.0",
      integrations: [Sentry.browserTracingIntegration()],
      environment,

      // Capture Replay for 100% of sessions with an error
      replaysOnErrorSampleRate: 1.0,

      // Ignore network errors
      // reference:
      // https://github.com/getsentry/sentry-javascript/issues/6376#issuecomment-1337616848
      // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
      ignoreErrors: [
        /Network Error/i,
        /Fetch Error/i,
        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
      ],
      beforeSend(event, hint) {
        const error = hint.originalException;
        // Filter out all network, fetch, and xhr errors
        if (error instanceof Error) {
          if (
            error.message.match(/Network Error/i) ||
            error.message.match(/Fetch Error/i) ||
            error.message.match(/XHR Error/i) ||
            error.message.match(/adsbygoogle/i) ||
            error.message.match(/Failed to fetch/i)
          ) {
            return null;
          }
        }

        return event;
      },
    });
    // ref: https://docs.sentry.io/platforms/javascript/guides/gatsby/session-replay/#lazy-loading-replay
    import("@sentry/gatsby").then((lazyLoadedSentry) => {
      Sentry.addIntegration(
        lazyLoadedSentry.replayIntegration({
          blockAllMedia: false,
          unmask: [".sentry-unmask, [data-sentry-unmask]"],
        }),
      );
    });
  }

  init(process.env.GATSBY_AMPLITUDE_API_KEY, {
    defaultTracking: {
      pageViews: {
        trackOn: () => true,
        trackHistoryChanges: "all",
        eventType: Event.PageVisited,
      },
    },
    serverUrl: process.env.GATSBY_AMPLITUDE_SERVER_URL,
  }).promise.then(() => {
    if (isSentryEnabled) {
      Sentry.setTags({
        "amplitude.deviceId": getDeviceId(),
        "amplitude.sessionId": getSessionId(),
      });
    }
  });
};
