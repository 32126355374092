import React from "react";
import {
  Box,
  Center,
  HStack,
  Icon,
  keyframes,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { motion } from "framer-motion";

import AppStoreBadge from "../images/join/badge_appstore_onlight.inline.svg";
import PlayStoreBadge from "../images/join/badge_playstore_onlight.inline.svg";

import {
  formatContentfulLiveUpdates,
  InterceptedLink,
  isMobile,
} from "../utils";

import { DoorIcon } from "../icons";

import { AnalyticsLink } from "./analytics";

const JoinButton = ({
  showStores = false,
  text = "Become a\n Member",
  to = InterceptedLink.SIGN_UP,
  gaLabel = "become_a_member_large",
  buttonProps = {},
  ...otherProps
}) => {
  return (
    <VStack gap="5.25rem" {...otherProps}>
      <AnalyticsLink gaLabel={gaLabel} to={to}>
        <Box
          as="button"
          w="20rem"
          h="20rem"
          position="relative"
          role="group"
          aria-label={text}
          {...buttonProps}
        >
          <AnimatedBackground />
          <VStack gap="1rem" zIndex={1}>
            <Icon
              as={DoorIcon}
              boxSize="5.5rem"
              zIndex={1}
              color="neutral.white"
            />
            <Text
              textStyle="cap2"
              lineHeight="133%"
              color="neutral.white"
              zIndex={1}
            >
              {text}
            </Text>
          </VStack>
        </Box>
      </AnalyticsLink>

      {showStores && <StoresSection />}
    </VStack>
  );
};

export const ContentfulJoinButton = (props) => {
  const { data, showStores = false, ...otherProps } = props;
  const inspectorProps = useContentfulInspectorMode();
  const liveData = formatContentfulLiveUpdates({ data });

  if (!liveData) return <JoinButton showStores={showStores} {...otherProps} />;

  const { url, longUrl, text, gaLabel } = liveData;
  const to = longUrl?.longUrl || url;

  return (
    <JoinButton
      buttonProps={{
        ...inspectorProps({
          entryId: data.contentful_id,
          fieldId: "text",
        }),
      }}
      showStores={showStores}
      text={text}
      to={to}
      gaLabel={gaLabel}
      {...otherProps}
    />
  );
};

const AnimatedBackground = () => {
  const spinKeyframes = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  `;

  const animation = `${spinKeyframes} 12s linear infinite`;

  return (
    <Box
      as={motion.div}
      position="absolute"
      h="100%"
      w="100%"
      top="0"
      animation={animation}
      bgColor="neutral.800"
      borderRadius="6rem"
      transition="background-color 150ms cubic-bezier(0.3, 0, 0, 1), border-radius 300ms cubic-bezier(0.3, 0, 0, 1)"
      _groupHover={{
        borderRadius: "11.25rem",
        bgColor: "marine.400",
        transition:
          "background-color 250ms cubic-bezier(0.3, 0, 0, 1), border-radius 1000ms cubic-bezier(0.3, 0, 0, 1)",
      }}
    />
  );
};

const StoresSection = () => {
  return (
    <Box>
      <Text textStyle="body2" textAlign="center">
        Available now on iOS and Android
      </Text>
      <Box height="1rem" />
      <Center>
        <HStack spacing="1.5rem">
          <AnalyticsLink
            to={process.env.GATSBY_APP_STORE_URL}
            gaLabel="download_appstore"
            aria-label="App Store"
            {...(isMobile() ? { target: "_self" } : {})}
          >
            <AppStoreBadge height="3.75rem" />
          </AnalyticsLink>
          <AnalyticsLink
            to={process.env.GATSBY_PLAYSTORE_STORE_URL}
            gaLabel="download_playstore"
            aria-label="Google Play Store"
            {...(isMobile() ? { target: "_self" } : {})}
          >
            <PlayStoreBadge height="3.75rem" />
          </AnalyticsLink>
        </HStack>
      </Center>
    </Box>
  );
};

export default JoinButton;
