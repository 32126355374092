import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import textStyles from "../text_styles";

const { definePartsStyle } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const boxShadowStyles = {
  boxShadow: "none",
  _focus: {
    boxShadow: "none",
  },
  _focusVisible: {
    boxShadow: "none",
  },
  _invalid: {
    boxShadow: "none",
  },
  _hover: {
    boxShadow: "none",
  },
};

const baseStyle = definePartsStyle({
  field: {
    ...textStyles.body1,
    ...boxShadowStyles,
    color: "neutral.800",
    px: "1.5rem",
    py: { base: "1.25rem", md: "0.75rem" },
    opacity: 0.8,
    borderRadius: "0.75rem",
    _placeholder: {
      color: "neutral.300",
    },
    _hover: {
      _invalid: {
        borderColor: "red.500",
      },
    },
  },
});

export default {
  baseStyle,
  // broken in chakra UI
  // reference: https://stackoverflow.com/questions/70809036/how-can-i-overwrite-styles-of-an-autofilled-input-when-using-chakra-ui
  variants: {
    filled: {
      bg: "neutral.25",
    },
    outline: {
      field: {
        ...boxShadowStyles,
        color: "coldstone.50",
        _placeholder: { color: "coldstone.600" },
        borderWidth: "1.5px",
        borderColor: "coldstone.300",
        borderRadius: "0.75rem",
        _hover: {
          _focus: {
            borderColor: "coldstone.300",
          },
          _focusVisible: {
            borderColor: "coldstone.300",
          },
        },
      },
    },
  },
  defaultProps: {
    variant: "backgroundFix",
  },
};
