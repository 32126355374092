import { getImage } from "gatsby-plugin-image";

export const episodeToInsider = (contentfulEpisode) => {
  const { id, slug, title, subtitle, date, thumbnail, description } =
    contentfulEpisode;

  return {
    id: id,
    link: "/episode/" + slug,
    title: title,
    subtitle: subtitle,
    date: date,
    colorScheme: "vulcan",
    tagVariant: "vulcan",
    series: "Podcast",
    thumbnail: getImage(thumbnail?.localFile),
    excerpt: description?.description,
  };
};
