import React from "react";
import {
  Code,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

import { useProcessedPath } from "../hooks/useProcessedPath";

import {
  EventProperty,
  getTrackingProps,
  isAppLink,
  isExternalLink,
  isSignUpLink,
  showAnalyticsLabel,
  Subcategory,
  trackClick,
  trackModalViewed,
} from "../utils";

import LiteOnboardingModal from "./lite_onboarding_modal";

// gaLabel: google analytics event label ID
// reference: https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const AnalyticsLink = ({
  children,
  gaLabel,
  gaCategory,
  to,
  onClick,
  trackingProperties,
  ...otherProps
}) => {
  const path = useProcessedPath(to);

  const properties = { ...trackingProperties, gaLabel, gaCategory, to: path };
  const trackingProps = getTrackingProps(properties);

  // We force our app links to open in the same tab because:
  // 1. Opening of native apps will only work if it opens up in the same tab
  //    for Chrome incognito.
  // 2. We want users to feel like they are still on the same platform when
  //    they click to navigate to the web app.
  const forceToOpenCurrentTab = isAppLink(path);
  const liteOnboardingModal = useDisclosure();

  if (isSignUpLink(path)) {
    return (
      <>
        <AnalyticsLabel gaLabel={gaLabel}>
          <Link
            {...trackingProps}
            variant="unstyled"
            onClick={(e) => {
              trackClick(properties);
              if (onClick) onClick(e);
              trackModalViewed({
                [EventProperty.Subcategory]: Subcategory.LiteOnboarding,
              });
              liteOnboardingModal.onOpen();
            }}
            {...otherProps}
          >
            {children}
          </Link>
        </AnalyticsLabel>

        <LiteOnboardingModal
          to={path}
          isOpen={liteOnboardingModal.isOpen}
          onClose={liteOnboardingModal.onClose}
        />
      </>
    );
  }

  if (isExternalLink(path)) {
    return (
      <AnalyticsLabel gaLabel={gaLabel}>
        <Link
          {...trackingProps}
          target={forceToOpenCurrentTab ? "_self" : "_blank"}
          rel="noopener"
          href={path}
          variant="unstyled"
          onClick={(e) => {
            trackClick(properties);
            if (onClick) onClick(e);
          }}
          {...otherProps}
        >
          {children}
        </Link>
      </AnalyticsLabel>
    );
  } else {
    // internal link
    return (
      <AnalyticsLabel gaLabel={gaLabel}>
        <Link
          {...trackingProps}
          as={GatsbyLink}
          to={path}
          variant="unstyled"
          onClick={(e) => {
            trackClick(properties);
            if (onClick) onClick(e);
          }}
          {...otherProps}
        >
          {children}
        </Link>
      </AnalyticsLabel>
    );
  }
};

export const AnalyticsLabel = ({ children, gaLabel }) => {
  if (!showAnalyticsLabel()) return children;
  return (
    <Popover trigger="hover" isOpen>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent bg="transparent" w="min-content">
        <Code>{gaLabel}</Code>
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  );
};
