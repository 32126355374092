import React, { createContext, useContext, useEffect, useState } from "react";

import {
  constructQueryParameters,
  CustomParameters,
  getAttributionData,
  IAttributionData,
  IKnownAttributionData,
} from "../utils";

import { usePageDataOrNull } from "../templates/contentful_page/context";

export const defaultAttributionData: IKnownAttributionData = {};

interface IAttributionDataContext {
  attributionData: IAttributionData;
  setAttributionData: (data: IAttributionData) => void;
}

export const AttributionData = createContext<IAttributionDataContext>({
  attributionData: defaultAttributionData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAttributionData: () => {},
});

export const useAttributionDataContext = () => {
  // Values of front door could be from:
  // 1) Contentful button's url field
  //    | which the string itself already comes with "?front_door=<value>"
  //    | should take the highest precedence
  // 2) Contentful page's front-door field
  //    | which is taken from PageContext,
  //    | should take the second highest precedence
  // 3) Url front_door query parameter
  //    | which could be set by an incoming ad/partner link
  //    | should be the lowest in precedence

  const { attributionData, setAttributionData } = useContext(AttributionData);
  let data: IAttributionData = { ...attributionData };

  // A page-level front door can be set via ContentfulPage's front-door field.
  // If set, all outbound app links on that page should contain this front_door
  // parameter.
  const pageData = usePageDataOrNull();
  const frontDoor = pageData?.frontDoor;
  if (frontDoor) {
    data = {
      ...data,
      [CustomParameters.FrontDoor]: frontDoor,
    };
  }

  const urlParams = constructQueryParameters(data);

  return {
    attributionData: data,
    setAttributionData,
    urlParams,
  };
};

export function AttributionDataProvider({ children }) {
  const [properties, setProperties] = useState<IAttributionData>(
    defaultAttributionData,
  );

  useEffect(() => {
    // window and document are not defined in the gatsby build process
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const attributionData = getAttributionData(
        window.location,
        document.referrer,
      );
      setProperties(attributionData);
    }
  }, []);

  return (
    <AttributionData.Provider
      value={{ attributionData: properties, setAttributionData: setProperties }}
    >
      {children}
    </AttributionData.Provider>
  );
}
