import React from "react";
import { useFormContext } from "react-hook-form";
import { Text } from "@chakra-ui/react";

export const ArtaSubmitErrorMessage = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const message = errors?.root?.server?.message;

  return message ? <Text color="red.500">{message}</Text> : null;
};
