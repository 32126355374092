import React from "react";
import {
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  UnorderedList,
} from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { kebabCase } from "lodash";

import { AnalyticsLink } from "../components/analytics";
import ContentfulTooltip from "../components/contentful_tooltip";
import JoinButton from "../components/join_button";

import {
  ContentfulLiveEditTypename,
  ContentfulTypename,
} from "../templates/contentful_page/enums";

export const renderContentfulRichText = (data, options = {}) => {
  const previewMaps = getRichTextPreviewMaps(data?.links);

  const mergedOptions = {
    ...richTextOptions(previewMaps),
    ...options,
    renderMark: {
      ...richTextOptions(previewMaps).renderMark,
      ...options.renderMark,
    },
    renderNode: {
      ...richTextOptions(previewMaps).renderNode,
      ...options.renderNode,
    },
  };

  if (!data) return null;

  // preview, only data.json is updated
  if (data.json) return documentToReactComponents(data.json, mergedOptions);

  // production, we can only retrieve data.raw
  if (data.raw) return renderRichText(data, mergedOptions);

  return null;
};

const richTextOptions = (previewMaps) => {
  const {
    // assetMap, only use this when we support adding images in the rich text editor
    entryMap,
  } = previewMaps;
  return {
    renderMark: {
      [MARKS.CODE]: (text) => {
        if (text === "[member]") {
          return <JoinButton />;
        }
        return text;
      },
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, gatsbyImageData } = node.data.target;
        const alt = description || title;
        if (gatsbyImageData) {
          return <GatsbyImage image={gatsbyImageData} alt={alt} />;
        }
        return null;
      },
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Text minH="14px">{children}</Text> // min height for empty paragraph
      ),
      [BLOCKS.HEADING_1]: (node) => {
        return node.content.map((item) => {
          const id = kebabCase(item.value);
          return (
            <h1 id={id} key={id}>
              {item.value}
            </h1>
          );
        });
      },
      [BLOCKS.HEADING_2]: (node) => {
        return node.content.map((item) => {
          const id = kebabCase(item.value);
          return (
            <h2 id={id} key={id}>
              {item.value}
            </h2>
          );
        });
      },
      [BLOCKS.HEADING_3]: (node) => {
        return node.content.map((item) => {
          const id = kebabCase(item.value);
          return (
            <h3 id={id} key={id}>
              {item.value}
            </h3>
          );
        });
      },
      [BLOCKS.HEADING_4]: (node) => {
        return node.content.map((item) => {
          const id = kebabCase(item.value);
          return (
            <h4 id={id} key={id}>
              {item.value}
            </h4>
          );
        });
      },
      [BLOCKS.HEADING_5]: (node) => {
        return node.content.map((item) => {
          const id = kebabCase(item.value);
          return (
            <h5 id={id} key={id}>
              {item.value}
            </h5>
          );
        });
      },
      [BLOCKS.HEADING_6]: (node) => {
        return node.content.map((item) => {
          const id = kebabCase(item.value);
          return (
            <h6 id={id} key={id}>
              {item.value}
            </h6>
          );
        });
      },
      [BLOCKS.TABLE]: (node, children) => {
        const tableContent = node.content;
        const firstRowContent = tableContent[0].content;
        const firstCell = firstRowContent[0];
        const hasHeader = firstCell.nodeType === BLOCKS.TABLE_HEADER_CELL;
        const header = hasHeader ? children[0] : null;
        const data = hasHeader ? children.slice(1) : children;
        return (
          <TableContainer whiteSpace="unset">
            <Table variant="brand">
              <Thead>{header}</Thead>
              <Tbody>{data}</Tbody>
            </Table>
          </TableContainer>
        );
      },
      [BLOCKS.TABLE_CELL]: (_node, children) => (
        <Td textAlign="inherit">{children}</Td>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
        <Th textAlign="inherit">{children}</Th>
      ),
      [BLOCKS.OL_LIST]: (_node, children) => (
        <OrderedList variant="contentful">{children}</OrderedList>
      ),
      [BLOCKS.UL_LIST]: (_node, children) => (
        <UnorderedList variant="contentful">{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (_node, children) => <ListItem>{children}</ListItem>,
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const { __typename, sys } = node.data.target;
        if (__typename === ContentfulTypename.Tooltip) {
          return <ContentfulTooltip data={node.data.target} sup inline />;
        }
        // ==== live preview ====
        // TODO: do proper parsing in formatContentfulLiveUpdates to avoid this
        const entry = entryMap.get(sys.id);
        if (!entry) return null;
        if (entry.__typename === ContentfulLiveEditTypename.Tooltip) {
          return <ContentfulTooltip data={entry} sup inline />;
        }

        return null;
      },
      [INLINES.HYPERLINK]: (node) => {
        const link = node.data.uri;
        const { value } = node.content[0];
        // if (link.includes("youtube.com") || link.includes("youtu.be")) {
        //   // Extract videoId from the URL
        //   const match =
        //     /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
        //       link,
        //     );
        //   const videoId = match && match[7].length === 11 ? match[7] : null;
        //   return (
        //     videoId && (
        //       <section className="video-container">
        //         <iframe
        //           className="video"
        //           title={`https://youtube.com/embed/${videoId}`}
        //           src={`https://youtube.com/embed/${videoId}`}
        //           allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        //           allowFullScreen
        //         />
        //       </section>
        //     )
        //   );
        // }

        // "Become A Member" button with custom link
        if (value === "[member-image]") {
          return <JoinButton to={link} />;
        }

        const processedLink = link.startsWith(process.env.GATSBY_BASE_URL)
          ? link.replace(process.env.GATSBY_BASE_URL, "")
          : link;

        return (
          <AnalyticsLink to={processedLink} variant="brand">
            {value}
          </AnalyticsLink>
        );
      },
    },
  };
};

// only for preview
// reference: https://github.com/whitep4nth3r/contentful-graphql-vs-rest/blob/main/pages/graphql.js
export const getRichTextPreviewMaps = (links) => {
  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  if (links?.assets?.block) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }
  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and add them to the map
  if (links?.entries?.block) {
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  if (links?.entries?.inline) {
    // loop through the inline linked entries and add them to the map
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  return { assetMap, entryMap };
};
