import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

import textStyles from "../text_styles";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const sizes = {
  md: definePartsStyle({
    control: defineStyle({
      boxSize: "1.125rem",
      borderRadius: "0.25rem",
    }),
    label: defineStyle({
      ...textStyles.body2,
      marginLeft: "1rem",
    }),
    icon: defineStyle({
      boxSize: "0.75rem",
      strokeWidth: "4",
    }),
  }),
};

export default defineMultiStyleConfig({
  sizes,
  parts: ["control"],
  variants: {
    // align checkbox with long label: https://github.com/chakra-ui/chakra-ui/issues/4154#issuecomment-855320259
    brand: {
      control: {
        alignSelf: "start",
        mt: 1,
        borderColor: "neutral.900",
        _checked: {
          bg: "transparent",
          color: "marine.400",
          borderColor: "marine.400",
          // unchange when hovered
          _hover: {
            bg: "transparent",
            color: "marine.400",
            borderColor: "marine.400",
          },
          _disabled: {
            bg: "transparent",
            color: "neutral.300",
            borderColor: "neutral.300",
          },
        },
      },
    },
  },
  defaultProps: {
    colorScheme: "marine",
  },
});
