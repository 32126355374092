import Button from "./button";
import Checkbox from "./checkbox";
import Divider from "./divider";
import Input from "./input";
import Link from "./link";
import List from "./list";
import Menu from "./menu";
import Modal from "./modal";
import Select from "./select";
import Table from "./table";
import Tag from "./tag";

export default {
  Button,
  Checkbox,
  Divider,
  Input,
  Link,
  List,
  Menu,
  Modal,
  Select,
  Table,
  Tag,
};
