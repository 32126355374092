import angular from "./angular";
import conical from "./conical";
import linear from "./linear";
import radial from "./radial";
import temp from "./temp";

export default {
  angular,
  conical,
  linear,
  radial,
  temp,
};
