import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const LevelUpIcon = createIcon({
  displayName: "LevelUpIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      d="m22.494 14.267c0-5.4124 4.3876-9.8 9.8-9.8s9.8 4.3876 9.8 9.8v0.7566l0.6556-0.3785c4.6872-2.7062 10.681-1.1002 13.387 3.587 2.7062 4.6873 1.1002 10.681-3.587 13.387l-0.6568 0.3791 0.6583 0.3801c4.6873 2.7062 6.2933 8.6998 3.5871 13.387-2.7062 4.6872-8.6998 6.2932-13.387 3.587l-0.6571-0.3794v0.7606c0 5.4124-4.3876 9.8-9.8 9.8s-9.8-4.3876-9.8-9.8v-0.7619l-0.6595 0.3807c-4.6872 2.7062-10.681 1.1002-13.387-3.587-2.7062-4.6873-1.1002-10.681 3.587-13.387l0.6583-0.3801-0.6567-0.3791c-4.6873-2.7062-6.2932-8.6998-3.5871-13.387 2.7062-4.6872 8.6998-6.2932 13.387-3.587l0.6579 0.3798v-0.7579zm9.8-5.4c-2.9824 0-5.4 2.4177-5.4 5.4v8.3789l-7.2579-4.1903c-2.5828-1.4912-5.8854-0.6063-7.3765 1.9765-1.4912 2.5828-0.6063 5.8854 1.9765 7.3766l7.2567 4.1896-7.2583 4.1906c-2.5828 1.4912-3.4677 4.7938-1.9765 7.3766 1.4912 2.5827 4.7937 3.4677 7.3765 1.9765l7.2595-4.1913v8.383c0 2.9823 2.4176 5.4 5.4 5.4 2.9823 0 5.4-2.4177 5.4-5.4v-8.3816l7.2571 4.1899c2.5828 1.4912 5.8854 0.6062 7.3766-1.9765 1.4911-2.5828 0.6062-5.8854-1.9766-7.3766l-7.2583-4.1906 7.2568-4.1896c2.5827-1.4912 3.4677-4.7938 1.9765-7.3766s-4.7938-3.4677-7.3765-1.9765l-7.2556 4.189v-8.3776c0-2.9823-2.4177-5.4-5.4-5.4z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  ),
});
