export default {
  // 1. svg absolute to relative, reference: https://yoksel.github.io/relative-clip-path/
  // 2. svg to css clip-path, reference: https://www.plantcss.com/css-clip-path-converter
  triangle: {
    default: {
      // 3. manually set y values that are close to 0% to -1% to avoid strange line artifacts
      clipPath:
        "polygon( 41.8% 9.9%,0% 100%,0% -1%,50% -1%,100% -1%,100% 100%,58.2% 9.9%,58.2% 9.9%,56.618% 6.849%,54.997% 4.476%,53.347% 2.781%,51.678% 1.764%,50% 1.425%,48.322% 1.764%,46.653% 2.781%,45.003% 4.476%,43.382% 6.849%,41.8% 9.9% )",
    },
    inverted: {
      // 3. manually set y values that are close to 100% to 101% to avoid strange line artifacts
      clipPath:
        "polygon( 41.8% 90.3%,0% 0%,0% 101%,100% 101%,100% 0%,58.2% 90.3%,58.2% 90.3%,56.618% 92.919%,54.997% 94.956%,53.347% 96.411%,51.678% 97.284%,50% 97.575%,48.322% 97.284%,46.653% 96.411%,45.003% 94.956%,43.382% 92.919%,41.8% 90.3% )",
    },
    background: {
      default: {
        clipPath:
          "polygon( 41.8% 8.6%,-1% 101%,50% 101%,101% 101%,58.2% 8.6%,58.2% 8.6%,56.618% 5.522%,54.997% 3.128%,53.347% 1.418%,51.678% 0.392%,50% 0.05%,48.322% 0.392%,46.653% 1.418%,45.003% 3.128%,43.382% 5.522%,41.8% 8.6% )",
      },
      inverted: {
        clipPath:
          "polygon(41.8% 91.4%, -1% -1%, 50% -1%, 101% -1%, 58.2% 91.4%, 58.2% 91.4%, 56.618% 94.478%, 54.997% 96.872%, 53.347% 98.582%, 51.678% 99.608%, 50% 99.95%, 48.322% 99.608%, 46.653% 98.582%, 45.003% 96.872%, 43.382% 94.478%, 41.8% 91.4%);",
      },
    },
  },
  divot: {
    default: {
      clipPath:
        "polygon( -1% 0%,45% 0%,45% 0%,45.24% 1.268%,45.478% 2.066%,45.715% 3.384%,45.947% 5.211%,46.175% 7.538%,46.397% 10.353%,46.611% 13.647%,46.818% 17.41%,47.014% 21.631%,47.2% 26.3%,48.9% 71.5%,48.9% 71.5%,49.091% 76.036%,49.302% 79.564%,49.526% 82.084%,49.761% 83.596%,50% 84.1%,50.239% 83.596%,50.474% 82.084%,50.698% 79.564%,50.909% 76.036%,51.1% 71.5%,52.8% 26.3%,52.8% 26.3%,52.986% 21.631%,53.182% 17.41%,53.389% 13.647%,53.603% 10.353%,53.825% 7.538%,54.053% 5.211%,54.285% 3.384%,54.522% 2.066%,54.76% 1.268%,55% 0%,101% 0%,101% 102%,-1% 102%,-1% 0% )",
    },
    background: {
      inverted: {
        // 3. manually decrease y values by 1 to avoid strange line artifacts
        clipPath:
          "polygon( 0% -1%,44.983% -1%,44.983% -1%,45.227% -1.318%,45.468% 0.262%,45.706% 1.822%,45.939% 3.984%,46.167% 6.737%,46.389% 10.068%,46.604% 13.966%,46.811% 18.418%,47.009% 23.412%,47.197% 28.937%,48.893% 82.523%,48.893% 82.523%,49.091% 87.912%,49.305% 92.103%,49.53% 95.097%,49.763% 96.893%,50% 97.491%,50.237% 96.893%,50.47% 95.097%,50.695% 92.103%,50.909% 87.912%,51.107% 82.523%,52.803% 28.937%,52.803% 28.937%,52.991% 23.412%,53.189% 18.418%,53.396% 13.966%,53.611% 10.068%,53.833% 6.737%,54.061% 3.984%,54.294% 1.822%,54.532% 0.262%,54.773% -1.318%,55.017% -1%,100% -1%,0% -1% );",
      },
    },
  },
};
