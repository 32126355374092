export enum Event {
  ButtonClicked = "marketing_website_button clicked",
  InfoSubmitted = "marketing_website_info submitted",
  InputEntered = "marketing_website_input entered",
  LinkClicked = "marketing_website_link clicked",
  ModalViewed = "marketing_website_modal viewed",
  NewsletterSubscribed = "marketing_website_newsletter subscribed",
  PageVisited = "marketing_website_page visited",
  PdfDownloaded = "marketing_website_pdf downloaded",
}

export enum EventProperty {
  Subcategory = "Subcategory",
}

export enum Subcategory {
  DownloadWhitePaper = "Download White Paper",
  LiteOnboarding = "Lite Onboarding",
}
