import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { motion } from "framer-motion";

import {
  renderContentfulRichText,
  useContentfulTextLiveUpdates,
} from "../utils";

const ContentfulText = (props) => {
  const { data = {}, options = {}, ...otherProps } = props;

  const inspectorProps = useContentfulInspectorMode();

  // only available in preview mode
  const liveData = useContentfulTextLiveUpdates(data);

  const { text, longText, richText } = liveData;

  // take the most "complicated field" available
  if (richText && (richText.raw || richText.json)) {
    return (
      <Box
        data-sentry-unmask
        {...inspectorProps({
          entryId: data.contentful_id,
          fieldId: "richText",
        })}
        {...otherProps}
      >
        {renderContentfulRichText(richText, options)}
      </Box>
    );
  }

  if (longText) {
    return (
      <Text
        data-sentry-unmask
        {...inspectorProps({
          entryId: data.contentful_id,
          fieldId: "longText",
        })}
        whiteSpace="break-spaces"
        {...otherProps}
      >
        {longText.longText}
      </Text>
    );
  }

  if (text) {
    return (
      <Text
        data-sentry-unmask
        {...inspectorProps({
          entryId: data.contentful_id,
          fieldId: "text",
        })}
        {...otherProps}
      >
        {text}
      </Text>
    );
  }

  return null;
};

export const TYPE_ANIMATION_STAGGER_CHILDREN = 0.007;

export const sentenceVariants = {
  hidden: {},
  visible: {
    opacity: 1,
    transition: { staggerChildren: TYPE_ANIMATION_STAGGER_CHILDREN },
  },
};

export const letterVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { opacity: { duration: 0 } } },
};

/** not available for richText */
export const TypeAnimationContentfulText = (props) => {
  const { data = {}, options = {}, stopAnimating, ...otherProps } = props;

  const inspectorProps = useContentfulInspectorMode();
  const liveData = useContentfulTextLiveUpdates(data);

  const { text, longText, richText } = liveData;

  // ignore richtext as its too complicated to handle
  if (stopAnimating || richText)
    return <ContentfulText data={data} options={options} {...otherProps} />;

  const fieldId = longText ? "longText" : "text";
  const whiteSpace = longText ? "break-spaces" : "normal";
  const string = longText ? longText.longText : text;

  return (
    <Text
      data-sentry-unmask
      as={motion.p}
      variants={sentenceVariants}
      initial="hidden"
      animate="visible"
      {...inspectorProps({
        entryId: data.contentful_id,
        fieldId,
      })}
      whiteSpace={whiteSpace}
      {...otherProps}
    >
      {string.split("").map((char, i) => (
        <motion.span key={`${char}-${i}`} variants={letterVariants}>
          {char}
        </motion.span>
      ))}
    </Text>
  );
};

export default ContentfulText;
