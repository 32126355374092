import { snakeCase } from "lodash";

// Follows the values in proto
export enum SignUpFrontDoor {
  // Unknown sign up front door.
  SIGN_UP_FRONT_DOOR_UNKNOWN = 0,
  // Default sign up front door.
  SIGN_UP_FRONT_DOOR_DEFAULT = 20,
  // Sign up front door is cash.
  SIGN_UP_FRONT_DOOR_CASH = 1,
  // Sign up front door is alts.
  SIGN_UP_FRONT_DOOR_ALTS = 2,
  // Sign up front door is wealthgen.
  SIGN_UP_FRONT_DOOR_WEALTHGEN = 3,
  // Sign up front door is directindex.
  SIGN_UP_FRONT_DOOR_DIRECTINDEX = 4,
}

export enum CustomParameters {
  FrontDoor = "front_door",
}

enum UtmParameters {
  UtmSource = "utm_source",
  UtmCampaignId = "utm_campaignid",
  UtmCampaign = "utm_campaign",
  UtmTerm = "utm_term",
  UtmMedium = "utm_medium",
  UtmContent = "utm_content",
  UtmAdId = "utm_adid",
}

enum AdIdentifiers {
  Google = "gclid",
  GoogleDoubleClick = "dclid",
  Facebook = "fbclid",
  Twitter = "twclid",
  Reddit = "rtd_cid",
  LinkedIn = "li_fat_id",
  GoogleWebToApp = "gbraid", // see https://support.google.com/analytics/answer/11367152
  GoogleAppToWeb = "wbraid", // see https://support.google.com/analytics/answer/11367152
}

enum Everflow {
  TransactionId = "everflow_tid",
  AffiliateName = "everflow_affl_name",
  OfferName = "everflow_offer_name",
}

export type IKnownAttributionData = IReferrer &
  ICustomParameters &
  IUtmParameters &
  IAdIdentifiers &
  IEverflow;
export type IAttributionData = IKnownAttributionData & IUnknownParameters;
type IReferrer = { referrer?: string };
type ICustomParameters = { [key in CustomParameters]?: string };
type IUtmParameters = { [key in UtmParameters]?: string };
type IAdIdentifiers = { [key in AdIdentifiers]?: string };
type IEverflow = { [key in Everflow]?: string };
type IUnknownParameters = { [key: string]: string | undefined };

/**
 * Parses the query parameters in the provided url into an object.
 * Also attempts to infer additional attribution data based on
 * established business logic.
 * @param url The url to parse attribution data from.
 * @param referrer The site which user came from to reach our site.
 * @returns An object of attribution data, containing those taken straight
 *          from the provided url as well as those that were inferred.
 */
export function getAttributionData(
  url: Location,
  referrer?: string,
): IAttributionData {
  const urlParams = new URLSearchParams(url.search);
  const partialAttributionData = {};
  // Take all available query parameters in the url first.
  for (const [key, value] of urlParams.entries()) {
    partialAttributionData[key] = value;
  }
  let attributionData: IAttributionData = {
    referrer,
    ...partialAttributionData,
  };

  // Some known attribution data, when not provided, can still be inferred
  // from some other known attribution data if they exist. We try to infer
  // those below and append them to the list of attribution data.

  // In the case where an ad identifier is available but utm_medium is not,
  // set the corresponding utm_medium for that ad identifier.
  // In the rare case that there are multiple ad identifiers in the url,
  // it is not possible to evaluate what the utm_medium is so it is skipped.
  const adIdentifiers = Object.keys(attributionData).filter((key) =>
    Object.values(AdIdentifiers).includes(key as AdIdentifiers),
  );
  const hasOnlyOneAdIdentifierData = adIdentifiers.length === 1;
  const isUtmMediumEmpty =
    attributionData[UtmParameters.UtmMedium] === undefined;
  if (isUtmMediumEmpty && hasOnlyOneAdIdentifierData) {
    switch (adIdentifiers[0]) {
      case AdIdentifiers.Google:
      case AdIdentifiers.GoogleDoubleClick:
      case AdIdentifiers.GoogleWebToApp:
      case AdIdentifiers.GoogleAppToWeb:
        attributionData[UtmParameters.UtmMedium] = "paid_search";
        break;
      case AdIdentifiers.Facebook:
      case AdIdentifiers.Twitter:
      case AdIdentifiers.Reddit:
      case AdIdentifiers.LinkedIn:
        attributionData[UtmParameters.UtmMedium] = "paid_social";
        break;
    }

    // Do an early return as if there is an ad identifier, it must not be
    // organic traffic. No need to run the rest of the code below.
    return attributionData;
  }

  // If ad identifier is not available, the traffic may be organic.
  // Traffic is considered organic if there is no attribution data (excluding
  // referrer) in the url. If it is organic traffic, attempt to derive utm
  // parameters from referrer.
  const hasPartialAttributionData =
    Object.keys(partialAttributionData).length > 0;
  const isOrganicTraffic = !hasPartialAttributionData;
  if (isOrganicTraffic) {
    attributionData = {
      ...attributionData,
      ...getUtmParametersFromReferrer(document.referrer),
    };
  }

  return attributionData;
}

export function getUtmParametersFromReferrer(referrer: string): IUtmParameters {
  const utmParameters: IUtmParameters = {};

  // If referrer does not exist, we simply assume it is direct traffic.
  if (!referrer) {
    utmParameters[UtmParameters.UtmMedium] = "organic_direct";
    return utmParameters;
  }

  // Otherwise, use referrer's hostname to set utm_source and utm_medium.
  const url = new URL(referrer);
  let hostname: string;
  if (url.protocol === "android-app:") {
    // If referrer is an app, its value is e.g. android-app://com.linkedin.android/
    // iOS apps do not set the referrer header.
    // url.pathname is unreliable - it works in Chrome but not in Node. Use the
    // full referrer value just in case.
    // Get the app name from the pathname by removing all `/`
    hostname = referrer.replace("android-app://", "").replace(/\//g, "");
  } else {
    hostname = url.hostname;
  }
  // By default, set utm_source to the hostname.
  utmParameters[UtmParameters.UtmSource] = hostname;

  // Add overrides for known hostnames here
  // Match:
  // - Start of string or a dot
  //   - This catches the host without a subdomain (google.com)
  //   - This catches subdomains (www.google.com, images.google.com)
  //   - This prevents false positives (somesite-google.com)
  // - The keyword e.g. "google"
  // - A dot
  //   - Cannot hardcode ".com" in case of ".co.uk" etc.
  // This WILL match on phishing hosts like *.google.com.badsite.info, but we
  // only use this for attribution, so it's not a security risk.
  // There's no easy way to regex for all valid TLDs + ".android"
  if (hostname.match(/(^|\.)google\./g)) {
    utmParameters[UtmParameters.UtmSource] = "Google";
    utmParameters[UtmParameters.UtmMedium] = "organic_search";
  } else if (hostname.match(/(^|\.)linkedin\./g)) {
    utmParameters[UtmParameters.UtmSource] = "LinkedIn";
    utmParameters[UtmParameters.UtmMedium] = "organic_social";
  }

  return utmParameters;
}

export const hasQueryParameters = (url: string) => {
  return url.includes("?");
};

export const appendQueryParametersToUrl = (
  url: string,
  params: URLSearchParams,
) => {
  // No query parameters to append, return url as is.
  if (params.toString() === "") return url;

  // No query parameters in the existing url, hence simply append the
  // provided query parameters to the url.
  if (!hasQueryParameters(url)) {
    return url.concat("?", params.toString());
  }

  // There are query parameters in the existing url, determine which
  // provided query parameters should be appended to the url.
  //
  // The query parameters that are already present in the given url takes
  // precedence. Hence, only append the params that are new.
  //
  // Example, if
  // url = https://artafinance.com/?front_door=default and
  // params = { front_door: "new" },
  // the output is https://artafinance.com/?front_door=default
  const existingParams = new URLSearchParams(url.split("?")[1]);
  const newParams = new URLSearchParams();
  params.forEach((value, key) => {
    if (!existingParams.has(key)) {
      newParams.append(key, value);
    }
  });

  if (newParams.toString() === "") return url;
  return url.concat("&", newParams.toString());
};

/**
 * Construct query parameters from the provided attribution data object.
 * @param attributionData
 * @param excludeList Attributes to exclude from the final result.
 * @returns A URLSearchParams object.
 */
export function constructQueryParameters(
  attributionData: IAttributionData,
  // Exclude properties that are not as practical to forward via url params
  excludeList: Array<string> = ["referrer"],
): URLSearchParams {
  const urlParams = new URLSearchParams();
  const properties = Object.keys(attributionData);

  properties.forEach((property) => {
    const value = attributionData[property];
    if (excludeList.includes(property) || !value) return;
    urlParams.append(property, value);
  });

  return urlParams;
}

export function maybeConstructAttributionProto(
  attributionData: IAttributionData,
) {
  if (Object.keys(attributionData).length === 0) return null;

  const marketingAttributionMetadata = {
    utm_parameters: constructUtmParameters(attributionData),
    ad_identifiers: constructAdIdentifiers(attributionData),
    partner_identifier: constructPartnerIdentifiers(attributionData),
    sign_up_front_doors: getFrontDoors(attributionData),
  };

  return marketingAttributionMetadata;
}

export function constructUtmParameters(attributionData: IAttributionData) {
  return Object.values(UtmParameters).reduce((acc, curr) => {
    const attributionValue = attributionData[curr];
    if (attributionValue) {
      acc = { ...acc, [curr]: attributionValue };
    }
    return acc;
  }, {});
}

function constructAdIdentifiers(attributionData: IAttributionData) {
  return Object.values(AdIdentifiers).reduce<
    Array<{ ad_id: { [key: string]: string } }>
  >((acc, curr) => {
    const attributionValue = attributionData[curr];
    if (attributionValue) {
      acc.push({ ad_id: { [curr]: attributionValue } });
    }
    return acc;
  }, []);
}

function constructPartnerIdentifiers(attributionData: IAttributionData) {
  const everflowId = Object.values(Everflow).reduce((acc, curr) => {
    const attributionValue = attributionData[curr];
    if (attributionValue) {
      acc = { ...acc, [curr]: attributionValue };
    }
    return acc;
  }, {});

  if (Object.keys(everflowId).length > 0) {
    return {
      partner_id: {
        everflow_id: everflowId,
      },
    };
  }
  return {};
}

export function getFrontDoors(
  attributionData: IAttributionData,
): Array<SignUpFrontDoor> {
  const frontDoors = new Set<SignUpFrontDoor>();

  const frontDoor = attributionData[CustomParameters.FrontDoor];
  if (frontDoor) {
    frontDoors.add(getSignUpFrontDoorEnum(frontDoor));
  }

  /// Check if user might have came in through multiple front doors
  /// or a front door not marked by the front_door param
  const derivedFrontDoor = getFrontDoorFromUtmParameters(attributionData);
  if (derivedFrontDoor != null) {
    frontDoors.add(getSignUpFrontDoorEnum(derivedFrontDoor));
  }

  return Array.from(frontDoors);
}

export function getFrontDoorFromUtmParameters(
  attributionData: IAttributionData,
): string | null {
  const utmContent = attributionData[UtmParameters.UtmContent];
  if (!utmContent) return null;

  const delimiter = "..";

  /// Format of utm_content is coordinated to be
  /// conversion tag..product names..front door
  const contents = utmContent.split(delimiter);
  if (contents.length === 3) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, __, frontDoor] = contents;
    return frontDoor || null;
  }

  console.log(
    `Error attempting to get front door from utm_content [${utmContent}]`,
  );
  return null;
}

function createSignUpFrontDoorMapping(): Record<string, number> {
  const prefix = "SIGN_UP_FRONT_DOOR_";
  const map = {};
  for (const [key, value] of Object.entries(SignUpFrontDoor)) {
    // TypeScript double maps enums keys and values. We are looking to map
    // key (e.g. SIGN_UP_FRONT_DOOR_CASH) to value (e.g. 1), so only add to
    // the map if the key is really a key. (e.g. is SIGN_UP_FRONT_DOOR_CASH,
    // not 1)
    const keyIsNaN = isNaN(Number(key));
    if (keyIsNaN) {
      const normalizedKey = key.replace(prefix, "").toLowerCase();
      map[normalizedKey] = value;
    }
  }
  return map;
}

export function getSignUpFrontDoorEnum(frontDoor: string): SignUpFrontDoor {
  const value = snakeCase(frontDoor).toLowerCase();
  const mapping = createSignUpFrontDoorMapping();

  const supportedValues = { ...mapping };
  delete supportedValues["unknown"];
  delete supportedValues["default"];

  return supportedValues[value] || SignUpFrontDoor.SIGN_UP_FRONT_DOOR_UNKNOWN;
}
