export default {
  shadows: {
    card: "0px 16px 24px 0px rgba(13, 13, 13, 0.24), 0px 0px 1px 0px rgba(13, 13, 13, 0.32)",
    light: {
      80: "0px 0px 1px 0px rgba(75, 85, 82, 0.24), 0px 8px 16px 0px rgba(75, 85, 82, 0.40)",
      100: "0px 0px 1px 0px rgba(75, 85, 82, 0.24), 0px 16px 24px 0px rgba(75, 85, 82, 0.40)",
    },
    agnostic: {
      asset:
        "0px 16px 24px 0px rgba(13, 13, 13, 0.16), 0px 0px 2px 0px rgba(13, 13, 13, 0.16)",
    },
  },
};
