import React from "react";
import { getImage } from "gatsby-plugin-image";

export const markdownToInsider = (markdownRemark) => {
  const {
    id,
    html,
    timeToRead,
    frontmatter: {
      slug,
      title,
      subtitle,
      date,
      colorScheme,
      series,
      billboardImage,
      imageCenterY,
      excerpt,
      tags,
    },
  } = markdownRemark;

  const featured = (tags ?? "").includes("Featured");

  return {
    id: id,
    slug: slug,
    link: "/insights/" + slug,
    title: title,
    subtitle: subtitle,
    timeToRead: timeToRead,
    date: date,
    colorScheme: colorScheme,
    series: series,
    billboardImage: getImage(billboardImage),
    imageCenterY: imageCenterY,
    excerpt: excerpt,
    featured: featured,
    body: <div dangerouslySetInnerHTML={{ __html: formatHtml(html) }} />,
  };
};

function formatHtml(content) {
  if (!content) return "";

  return content.replace(/\[member\]/g, () => {
    return `
      <div class="button-link-wrapper">
        <a class="button-link" href=${process.env.GATSBY_APP_URL}>
          <img class="member" src="/insights/door-join-cta.svg" />
          BECOME A<br />
          MEMBER
        </a>
      </div>
   `;
  });
}
