import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const LongLeftArrowIcon = createIcon({
  displayName: "LongLeftArrowIcon",
  viewBox: "0 0 107 64",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="64" fill="none">
      <g fill="currentColor">
        <path d="M10.667 32a2.667 2.667 0 0 1 2.666-2.667h80a2.667 2.667 0 1 1 0 5.334h-80A2.667 2.667 0 0 1 10.667 32Z" />
        <path d="M11.448 30.114a2.667 2.667 0 0 1 3.771 0l10.667 10.667a2.667 2.667 0 1 1-3.771 3.771L11.447 33.886a2.667 2.667 0 0 1 0-3.772Z" />
        <path d="M25.886 19.448a2.667 2.667 0 0 1 0 3.77L15.219 33.887a2.667 2.667 0 1 1-3.771-3.772l10.666-10.666a2.667 2.667 0 0 1 3.772 0Z" />
      </g>
    </svg>
  ),
});
