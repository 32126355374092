import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brand = defineStyle({
  textDecoration: "underline",
  color: "marine.500",
  _hover: {
    color: "marine.400",
  },
});

const unstyled = defineStyle({
  textDecoration: "none",
  _hover: {
    textDecoration: "none",
  },
});

export default defineStyleConfig({
  variants: { brand, unstyled },
});
