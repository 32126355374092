import { DevExperiment } from "./enums";
import { IDevExperiment } from "./interfaces";

// These are experiments managed and integrated into code by a developer
export const devExperimentsData: Array<IDevExperiment> = [
  {
    experimentId: DevExperiment.LiteOnboarding,
    variantCollection: [
      { variantId: "flutter-app" },
      { variantId: "marketing-site" },
    ],
    fallbackVariantId: "flutter-app",
  },
];
