// window and document are not defined in the gatsby build process
const isBrowser = typeof window !== "undefined";

// append ?preview to the url to enable preview mode
export const isPreview = () => {
  if (!isBrowser) return false;
  const queryString = window.location.search;
  if (window.location.pathname === "/live-preview") return true;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.has("preview");
};
