import { listAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(listAnatomy.keys);

const contentful = definePartsStyle((props) => {
  const { as, listStyleType, marginInline } = props;

  const container =
    as === "ol"
      ? {}
      : {
          "&": {
            // Using & to raise the specificity to
            // override Chakra UI's default values
            marginInline: marginInline ?? "2.5rem",
            listStyleType: listStyleType ?? '"•  "',
            listStylePosition: "outside",
          },
        };

  return {
    container,
    item: {
      "& p": {
        display: "inline",
      },
    },
  };
});

export default defineMultiStyleConfig({
  variants: {
    contentful,
  },
});
