import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-fetch";

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/`,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const params = new URLSearchParams(location.search);
  const secret = params.get("secret");
  return {
    headers: {
      ...headers,
      authorization: secret ? `Bearer ${secret}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
