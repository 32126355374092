import textures from "../textures";

const gradientTexture = textures.noise[256];

export default {
  hd: {
    ultra: {
      colors: {
        backgroundImage:
          "conic-gradient(from 0deg at 50% 50%, tropic.600 0deg, lunar.400 60deg, tropic.400 120deg, solar.400 180deg, lunar.300 240deg, marine.300 300deg, forest.200 360deg)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.2,
      },
    },
    marine: {
      colors: {
        backgroundImage:
          "conic-gradient(from 0deg at 50% 50%, forest.700 180deg, marine.400 180deg, lunar.200 360deg)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.72,
      },
    },
  },
  ultra: {
    soft: {
      light: {
        colors: {
          backgroundImage:
            "conic-gradient(from 45deg at 50% 50%, forest.200 0deg, marine.300 24deg, lunar.400 81deg, tropic.400 131deg, solar.400 180deg, solar.300 231deg, lunar.200 279deg, forest.200 328deg)",
        },
        texture: {
          ...gradientTexture,
          opacity: 0.8,
        },
      },
    },
  },
  forest: {
    soft: {
      colors: {
        backgroundImage:
          "conic-gradient(from 45deg at 50% 50%, #99BF98 0deg, forest.500 45deg, solar.400 180deg, solar.200 318deg, #99BF98 360deg)",
      },
      texture: {
        ...gradientTexture,
        opacity: 0.72,
      },
    },
  },
  marine: {
    soft: {
      dark: {
        colors: {
          backgroundImage:
            "conic-gradient(from 45deg at 50% 50%, #7BA6BF 0deg, forest.600 41deg, marine.400 180deg, lunar.200 316deg, #7BA6BF 360deg)",
        },
        texture: {
          ...gradientTexture,
          opacity: 0.72,
        },
      },
    },
  },
};
