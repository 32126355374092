import {
  appendQueryParametersToUrl,
  constructAppLinkUrl,
  constructUtmParameters,
  getDevVariant,
  isAppLink,
  isForwardUtmLink,
  isSignUpLink,
} from "../utils";

import { useAmplitudeExperiment, useAttributionDataContext } from "../contexts";
import {
  DevExperiment,
  devExperimentsData,
} from "../templates/contentful_page/experiments";

/**
 * Append a path with the relevant attribution parameters based
 * on business logic.
 * @param path The path to process.
 * @returns The path appended with attribution parameters, if applicable.
 */
export const useProcessedPath = (path: string) => {
  const { attributionData, urlParams } = useAttributionDataContext();

  const { experiment, previewVariants } = useAmplitudeExperiment();
  let variantId = "flutter-app";
  const devExperiment = devExperimentsData.find(
    ({ experimentId }) => experimentId === DevExperiment.LiteOnboarding,
  );
  if (devExperiment) {
    variantId = getDevVariant(
      devExperiment,
      experiment,
      previewVariants,
    ).variantId;
  }

  if (isAppLink(path)) {
    // Forward all attribution parameters
    // for the app to track attribution data
    return appendQueryParametersToUrl(path, urlParams);
  }

  if (isForwardUtmLink(path)) {
    // Forward only utm parameters
    const utmParams = new URLSearchParams(
      constructUtmParameters(attributionData),
    );
    return appendQueryParametersToUrl(path, utmParams);
  }

  if (variantId === "flutter-app" && isSignUpLink(path)) {
    // Override path into our external app link if user falls under the
    // "go to Flutter app to sign up" bucket, i.e. from "/sign-to" to
    // https://app.artafinance.com
    const appLink = constructAppLinkUrl(path).toString();
    return appendQueryParametersToUrl(appLink, urlParams);
  }

  return path;
};
