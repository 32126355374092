import React, { createContext, useContext, useEffect, useState } from "react";
import { track as amplitudeTrack } from "@amplitude/analytics-browser";
import {
  Experiment,
  ExperimentClient,
  Exposure,
} from "@amplitude/experiment-js-client";
import * as Sentry from "@sentry/browser";

import { getPageProperties } from "../utils";

interface IAmplitudeExperimentContext {
  experiment?: ExperimentClient | null;
  previewVariants: IPreviewVariants;
  setPreviewVariants: React.Dispatch<React.SetStateAction<IPreviewVariants>>;
}

export interface IPreviewVariants {
  // The format corresponds to:
  // [experimentId]: { value: resolvedVariantId }
  [experimentId: string]: { value: string };
}

export const AmplitudeExperiment = createContext<IAmplitudeExperimentContext>({
  previewVariants: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPreviewVariants: () => {},
});

export const useAmplitudeExperiment = () => {
  return useContext(AmplitudeExperiment);
};

export function AmplitudeExperimentProvider({ children }) {
  const [experiment, setExperiment] = useState<ExperimentClient | null>();
  const [previewVariants, setPreviewVariants] = useState<IPreviewVariants>({});

  useEffect(() => {
    const startExperiment = async () => {
      const deploymentKey = process.env.GATSBY_AMPLITUDE_DEPLOYMENT_KEY;
      if (!deploymentKey) {
        setExperiment(null);
        return;
      }

      try {
        const experiment = Experiment.initializeWithAmplitudeAnalytics(
          deploymentKey,
          {
            exposureTrackingProvider: new CustomExposureTrackingProvider(),
          },
        );
        await experiment.start();
        await experiment.fetch();
        setExperiment(experiment);
      } catch (e) {
        console.error(`Error: Initialization of experiments failed. ${e}`);
        setExperiment(null); // Initialize to null on failure to allow page to load
      }
    };

    startExperiment();
  }, []);

  return (
    <AmplitudeExperiment.Provider
      value={{ experiment, previewVariants, setPreviewVariants }}
    >
      {children}
    </AmplitudeExperiment.Provider>
  );
}

export interface IExposureTrackingProvider {
  track(exposure: Exposure): void;
}

// Using a custom exposure tracking provider because the default provided by
// Amplitude would send exposure events to all Amplitude Analytics instances
// but we want to send them to our main project only.
class CustomExposureTrackingProvider implements IExposureTrackingProvider {
  track(exposure: Exposure): void {
    // It seems that track will still be executed for a user that is not
    // bucketed into an experiment. When that happens, exposure will contain
    // only flag_key. Do not send exposure event if variant is missing.
    const { variant } = exposure;
    if (variant === undefined) return;

    const pageProperties = getPageProperties();
    amplitudeTrack({
      event_type: "$exposure",
      event_properties: { ...pageProperties, ...exposure },
    });
    Sentry.setTag(`experiment.${exposure.flag_key}`, exposure.variant);
  }
}
