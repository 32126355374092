import React from "react";
import { Checkbox, Icon } from "@chakra-ui/react";
import { IconCheck, IconMinus } from "@tabler/icons-react";

const ArtaCheckbox = ({ ...otherProps }) => {
  return <Checkbox icon={<CustomIcon />} variant="brand" {...otherProps} />;
};

const CustomIcon = (props) => {
  const { isIndeterminate, isChecked, ...rest } = props;

  return (
    <>
      {isChecked ? (
        <Icon as={isIndeterminate ? IconMinus : IconCheck} {...rest} />
      ) : null}
    </>
  );
};

export default ArtaCheckbox;
