import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brand = defineStyle({
  borderStyle: "solid",
  borderColor: "marine.300",
  opacity: 1,
  borderWidth: "0.25rem",
});

export default defineStyleConfig({
  variants: { brand },
});
