import clips from "./clips";
import gradients from "./gradients";
import textures from "./textures";

export default {
  clips,
  gradients,
  textures,
  hideScrollbar: {
    "&::-webkit-scrollbar": {
      display: "none" /* chrome and chromium based */,
    },
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
};
