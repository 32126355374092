import { useEffect } from "react";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { format } from "date-fns";

import { useQuery } from "@apollo/react-hooks";

import GET_PAGE from "../graphql/queries/GET_PAGE.graphql";
import GET_SECTION from "../graphql/queries/GET_SECTION.graphql";
import GET_TEXT from "../graphql/queries/GET_TEXT.graphql";
import {
  CONTENTFUL_LIVE_EDIT_TYPE_MAP,
  CONTENTFUL_TYPE_LIVE_EDIT_MAP,
  ContentfulLiveEditTypename,
} from "../templates/contentful_page/enums";

import { isPreview } from ".";

// format contentful live updates because the live preview doesn't work well with gatsby
// see: https://github.com/contentful/live-preview#integrating-with-gatsby

// contentful data model: https://www.contentful.com/developers/docs/concepts/data-model/
export const formatContentfulLiveUpdates = ({
  data,
  longTextIds = [], // for Text (Long)
  arrayIds = [], // for Array
  dateIds = [], // for Date
  dateFormat = "MMMM dd, yyyy", // for Date
}) => {
  let id;
  if (data) {
    id = data.contentful_id;
    if (data.sys && data.sys.id) id = data.sys.id;
  }
  // do not format if not in preview mode, in case i screw something up
  const skip = !id || !isPreview();

  const gatsbyTypename = data?.__typename;

  const contentfulTypename = CONTENTFUL_TYPE_LIVE_EDIT_MAP[gatsbyTypename];

  // For live updates when using GraphQL, it is necessary to fetch sys.id and __typename in your query.
  // reference: https://www.contentful.com/developers/docs/tutorials/general/live-preview/#set-up-live-updates
  const updated = useContentfulLiveUpdates(
    {
      ...data,
      sys: { id },
      // use gatsby typename as a fallback in case formatContentfulToGatsby didnt convert properly
      // currently, Tooltip is not converted when included in rich text
      __typename: contentfulTypename ?? gatsbyTypename,
    },
    { skip },
  );

  if (skip) return data;

  return formatContentfulToGatsby({
    data: {
      ...data,
      ...updated,
    },
    longTextIds,
    arrayIds,
    dateIds,
    dateFormat,
  });
};

export const useContentfulPageLiveUpdates = (originalData) => {
  const id = originalData?.contentful_id;

  // do not format if not in preview mode, in case i screw something up
  let skip = !id || !isPreview();

  const { data: queryData } = useQuery(GET_PAGE, { variables: { id }, skip });

  // For live updates when using GraphQL, it is necessary to fetch sys.id and __typename in your query.
  // reference: https://www.contentful.com/developers/docs/tutorials/general/live-preview/#set-up-live-updates
  const updated = useContentfulLiveUpdates(
    {
      sys: { id },
      __typename: ContentfulLiveEditTypename.Page,
    },
    { skip },
  );

  const data = { ...(queryData?.page || {}), ...updated };

  if (!data.pageId) skip = true;

  if (skip) return originalData;

  return formatContentfulToGatsby({
    data,
    arrayIds: ["sections"],
  });
};

export const useContentfulSectionLiveUpdates = (originalData) => {
  const id = originalData?.contentful_id;

  // do not format if not in preview mode, in case i screw something up
  let skip = !id || !isPreview();

  const { data, refetch } = useQuery(GET_SECTION, { variables: { id }, skip });

  // For live updates when using GraphQL, it is necessary to fetch sys.id and __typename in your query.
  // reference: https://www.contentful.com/developers/docs/tutorials/general/live-preview/#set-up-live-updates
  const updated = useContentfulLiveUpdates(
    {
      sys: { id },
      __typename: ContentfulLiveEditTypename.UiSection,
    },
    { skip },
  );

  useEffect(() => {
    if (!skip) refetch();
  }, [skip, refetch, updated]);

  if (skip) return originalData;

  return formatContentfulToGatsby({
    data: { ...(data ? data.section : {}) },
    arrayIds: ["items"],
    existingItems: [],
  });
};

export const useContentfulTextLiveUpdates = (originalData) => {
  const id = originalData?.contentful_id;

  // do not format if not in preview mode, in case i screw something up
  let skip = !id || !isPreview();

  const { refetch, data } = useQuery(GET_TEXT, { variables: { id }, skip });

  // For live updates when using GraphQL, it is necessary to fetch sys.id and __typename in your query.
  // reference: https://www.contentful.com/developers/docs/tutorials/general/live-preview/#set-up-live-updates
  const updated = useContentfulLiveUpdates(
    {
      sys: { id },
      __typename: ContentfulLiveEditTypename.UiText,
    },
    { skip },
  );

  useEffect(() => {
    if (!skip) refetch();
  }, [skip, refetch, updated]);

  if (skip) return originalData;

  return formatContentfulToGatsby({
    data: { ...(data ? data.uiText : {}) },
    longTextIds: ["longText"],
  });
};

const formatContentfulToGatsby = ({
  data,
  longTextIds = [], // for Text (Long)
  arrayIds = [], // for Array
  dateIds = [], // for Date
  dateFormat = "MMMM dd, yyyy", // for Date
}) => {
  let result = {};

  // longTextIds
  result = longTextIds
    .filter((key) => data[key])
    .reduce((acc, key) => {
      acc[key] = data[key][key] ? data[key] : { [key]: data[key] };
      return acc;
    }, result);

  // arrayIds
  // make sure "items" is included even when parent didn't include it
  const arrIds =
    data.__typename === ContentfulLiveEditTypename.UiSection ? ["items"] : [];
  const uniqueArrayIds = [...new Set([...arrayIds, ...arrIds])];
  result = uniqueArrayIds.reduce((acc, key) => {
    // contentful live updates adds "Collection" to array fields
    const liveDataArr = data[key + "Collection"];
    if (liveDataArr) {
      acc[key] = [];
      for (let i = 0; i < liveDataArr.items.length; i++) {
        const item = liveDataArr.items[i];
        const formattedItem = formatContentfulToGatsby({ data: item });
        acc[key].push(formattedItem);
      }
    } else {
      acc[key] = data[key];
    }
    return acc;
  }, result);

  // dateIds
  result = dateIds.reduce((acc, key) => {
    acc[key] = data[key] ? format(new Date(data[key]), dateFormat) : "";
    return acc;
  }, result);

  return {
    ...data,
    ...result,
    contentful_id: data.sys?.id,
    id: data.sys?.id,
    __typename: CONTENTFUL_LIVE_EDIT_TYPE_MAP[data.__typename],
  };
};
