import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const BoxIcon = createIcon({
  displayName: "BoxIcon",
  viewBox: "0 0 65 64",
  path: (
    <path
      d="M57.9513 14.7245L33.7707 3.23869C33.4525 3.08276 33.1005 2.99974 32.7424 2.99951C32.4574 2.99933 32.1685 3.05162 31.8898 3.16136C31.8303 3.18472 31.7717 3.21051 31.7142 3.23869L7.55663 14.7135C7.38047 14.7914 7.21576 14.8905 7.06573 15.0075C6.92359 15.1181 6.79669 15.2433 6.6862 15.38C6.36106 15.781 6.16624 16.292 6.16624 16.8485V16.8488C6.16624 16.8497 6.16624 16.8506 6.16624 16.8515V47.1494C6.16624 47.1495 6.16624 47.1495 6.16624 47.1496V47.1515C6.16624 48.1082 6.74198 48.9304 7.56586 49.2905L31.663 60.7367C31.9856 60.9052 32.3526 61.0005 32.7418 61.0005C33.125 61.0005 33.4868 60.908 33.8058 60.7443L57.9371 49.2819C58.0372 49.2371 58.1335 49.1854 58.2255 49.1274C58.635 48.871 58.9419 48.5022 59.1247 48.0813C59.1555 48.0104 59.183 47.9376 59.2068 47.8632C59.2882 47.6093 59.3264 47.3413 59.3173 47.071V16.9287C59.3274 16.6287 59.2792 16.3314 59.1783 16.0532C58.9935 15.5433 58.6318 15.0972 58.129 14.8149C58.0713 14.7824 58.012 14.7522 57.9513 14.7245ZM54.6506 20.5409L35.0751 29.8393V54.975L54.6506 45.6766V20.5409ZM30.4084 54.9744V29.8386L10.8329 20.5403V45.676L30.4084 54.9744ZM51.5469 16.8488L32.7424 7.91663L13.9379 16.8488L32.7424 25.7809L51.5469 16.8488Z"
      fill="currentColor"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  ),
});
