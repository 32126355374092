// reference: https://www.geeksforgeeks.org/remove-blank-attributes-from-a-javascript-object/#removing-null-values-from-an-object-using-reduce
export const removeBlankAttributes = (obj) => {
  if (obj instanceof Array) return obj.map(removeBlankAttributes);

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] =
        typeof value === "object" ? removeBlankAttributes(value) : value;
    }
    return acc;
  }, {});
};
