import { createContext, useContext } from "react";
import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import {
  formatContentfulSectionData,
  useContentfulSectionLiveUpdates,
} from "../../utils";

import { useAmplitudeExperiment } from "../../contexts";

import { SiteId } from "./enums";
import {
  IFormattedContentfulPageData,
  IFormattedGatsbyPageData,
} from "./interfaces";

// gatsby page context
export const GatsbyPageContext = createContext<IFormattedGatsbyPageData | null>(
  null,
);

// To be used in components that would render in both Contentful and
// non-Contentful pages so that null checks are required to be done.
export const useGatsbyPageDataOrNull = () => useContext(GatsbyPageContext);

export const useSite = () => {
  const gatsbyPageData = useGatsbyPageDataOrNull();
  return gatsbyPageData?.site || SiteId.Us;
};

export const useBaseUrl = () => {
  const site = useSite();
  return site === SiteId.Us ? "/" : `/${site}`;
};

export const useSiteUrl = (url: string) => {
  const site = useSite();
  return site === SiteId.Us ? url : `/${site}${url}`;
};

export const useShowWaitlist = () => {
  const site = useSite();
  if (site === SiteId.Us) return false;
  return true;
};

// contentful page context
export const PageContext = createContext<IFormattedContentfulPageData | null>(
  null,
);

// To be used in Contentful pages only.
//
// Contentful pages are always expected to have page data. Hence, we use this
// hook in Contentful pages so that we don't have to do null checks.
//
// If someone wrongfully deletes some content within page data that we access
// in code via direct indices (e.g. pageData.sections[0].texts[0]), the build
// will also fail and not deploy to production. This is a desired behavior.
export const usePageData = () => {
  const pageDataContext = useContext(PageContext);

  // If you get this error thrown, you are likely using this hook in a
  // non-Contentful page. Consider using the usePageDataOrNull hook instead.
  if (!pageDataContext) {
    throw new Error(
      "pageDataContext has to be used within <PageContext.Provider>",
    );
  }

  return pageDataContext;
};

// To be used in components that would render in both Contentful and
// non-Contentful pages so that null checks are required to be done.
export const usePageDataOrNull = () => {
  const pageDataContext = useContext(PageContext);
  return pageDataContext;
};

export const useLiveSectionData = (data) => {
  const inspectorProps = useContentfulInspectorMode();
  const { experiment, previewVariants } = useAmplitudeExperiment();

  const liveData = useContentfulSectionLiveUpdates(data);
  const formatted = formatContentfulSectionData(
    liveData.items,
    experiment,
    previewVariants,
  );

  const sectionData = {
    ...liveData,
    ...formatted,
  };

  return {
    sectionData,
    liveEditProps: inspectorProps({
      entryId: data.contentful_id,
      fieldId: liveData.config ? "config" : "items",
    }),
  };
};
