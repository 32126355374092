import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

import { ConnectForm, useArtaFormContext, useTrackInputEntered } from ".";

export const ArtaInput = ({ name, helpText, ...otherProps }) => {
  const { trackingProperties } = useArtaFormContext();
  return (
    <ConnectForm>
      {(methods) => {
        const { formState, getFieldState, register } = methods;
        const { isSubmitting } = formState;
        const fieldState = getFieldState(name, formState);
        const { isTouched, error } = fieldState;

        const showError = isTouched && error;
        useTrackInputEntered({ methods, trackingProperties, name });

        return (
          <FormControl isInvalid={showError}>
            <Input
              {...register(name)}
              variant="outline"
              size="xl"
              color="netural.900"
              focusBorderColor="neutral.900"
              _hover={{
                _focus: {
                  borderColor: "neutral.900",
                },
                _focusVisible: {
                  borderColor: "neutral.900",
                },
              }}
              isDisabled={isSubmitting}
              {...otherProps}
            />
            {showError ? (
              <FormErrorMessage>{error.message}</FormErrorMessage>
            ) : helpText ? (
              <FormHelperText>{helpText}</FormHelperText>
            ) : (
              // HACK: Keep the layout consistent when there is no help text, or when there is an error
              <FormHelperText>&nbsp;</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </ConnectForm>
  );
};
